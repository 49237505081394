import React, { useState, useEffect } from "react"
import Check from './../../svg-components/CheckIcon';

export default props => {
  let [ checked, setChecked ] = useState( false );
  let [ error, setError ] = useState( false );
  let [ load, setLoad ] = useState( false );

    useEffect(() => {
        if ( load ) {
            setChecked(false);
        }
    }, [props.reset])

    useEffect(() => {
        if ( !load ) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.data) {
            setChecked(props.data.value)
        }
    }, [props.data])

    useEffect(() => {
        if ( load ) {
            validate();
        }
    }, [props.forceValidate])

    const validate = () => {
        let val = checked;

        if ( props.required && !val) {
            setError(true);
            return false;

        } else {
            setError(false);
        }

        return true;    
    }

    const toggleChecked = () => {
        let status = !checked;
        setChecked(status);
        props.setFunction(status);
        // setChecked( !checked ); 
        // props.setFunction( !checked )
    }

    const keyPressed = (e) => {
        if ( e.code === "Enter" ) {
            toggleChecked();
        }
    }

    return (
        <div className={"radio-wrapper " + ( error ? 'error ' : '') + props.class}>
            <label className="container-radio">
                <div className="label">
                    {props.text}
                </div>
                <input type="checkbox" role="radio" tabIndex="0" name={props.name} value={checked} checked={checked} onKeyDown={ (e) => keyPressed(e)} onClick={ () => toggleChecked() } aria-label={checked ? `Odznacz ${props.text}` : `Zaznacz ${props.text}`} />
                <span className="checkmark" />
            </label>
        </div>
    )
}
