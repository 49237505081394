import React, { useState, useEffect } from 'react'
import MediaLoad from './../../../media-load/MediaLoad';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

let validateNumber = 0;
const Patterns = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                items: [],
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data.items) {
                props.onChange({
                    items: [],
                }, props.index)
            }else{
                checkNullImage();
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(props.data.items);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        saveData(items);
    }

    const addItem = (item) => {
        let newData = [...props.data.items, {
            image: item,
        }]
        saveData(newData);
    }

    const removeItem = (id) => {
        let newData = props.data.items.filter((item, i) => item.image.id === id ? false : item);
        saveData(newData);
    }

    const saveData = (data) => {
        props.onChange({
            ...props.data,
            items: data
        }, props.index)
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.patterns-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const checkNullImage = () => {
        let newData = props.data && props.data.items && props.data.items.filter(item => item.image && item.image.id ? item : false);
        saveData(newData)
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    return (
        <div className={error ? `section-wrapper patterns error-section patterns-${props.index}` : `section-wrapper patterns patterns-${props.index}`}>
            <label>Zdjęcia wzorów</label>
            <small>Pierwszy element będzie wyświetlony poza popupem</small>
            <DragDropContext onDragEnd={handleRLDDChange}>
                <Droppable droppableId="section-items" direction="horizontal">
                    {(provided) => (
                        <div className="section-items" {...provided.droppableProps} ref={provided.innerRef}>
                            {props.data && props.data.items && props.data.items.map((item, index) => (
                                <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                    {(provided) => provided && (
                                        <div className="patterns-content" {...provided.draggableProps} ref={provided.innerRef}>
                                            <div className="section-index">
                                            </div>
                                            <div className="images-box" {...provided.dragHandleProps} tabIndex="-1">
                                                {item.image && item.image.sizes &&
                                                    <img src={item.image.sizes.small} alt={item.image.alt} title={item.image.name} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                    )}
                </Droppable> 
            </DragDropContext>
            <div className="row-section">
                <MediaLoad forceValidate={ forceValidate } setFunction={ val => addItem(val)} value={props.data.items} only="image" type="patterns" items={props.data.items} removeItem={(id) => removeItem(id)} />
            </div>
        </div>
    );
}

export default Patterns;