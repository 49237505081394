import React, { useState, useEffect } from 'react'
import EditorComponent from '../../../editor/Editor';

let validateNumber = 0;
const ContactInfo = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ oldIndex, setOldIndex ] = useState(false);
    let [ refreshComponent, setRefreshComponent ] = useState(false);
    
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                editor: `
                    <h5>Profile Vox</h5>
                    <p>  Profile Vox Sp. z.o.o. sp. k </p>
                    <p>ul. Gdynska 143, 62-004 Czerwonak, Poland</p>
                    <br/>
                    <h5>General Information:</h5>
                    <p><a href="tel: +48 61 81 51 700">+48 61 81 51 700</a></p>
                `
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
            setOldIndex(props.index);
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data.editor) {
                props.onChange({
                    editor: `
                        <h5>Profile Vox</h5>
                        <p>  Profile Vox Sp. z.o.o. sp. k </p>
                        <p>ul. Gdynska 143, 62-004 Czerwonak, Poland</p>
                        <br/>
                        <h5>General Information:</h5>
                        <p><a href="tel: +48 61 81 51 700">+48 61 81 51 700</a></p>
                    `
                }, props.index)
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.index !== oldIndex && load) {
            refreshEditor();
        }
    }, [props.index])

    const refreshEditor = async() => {
        setOldIndex(props.index);
        await setRefreshComponent(true);
        await wait(100);
        await setRefreshComponent(false);
    }

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.contact-info-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const updateEditor = (value) => {
        props.onChange({
            editor: value
        }, props.index);
    }

    return (
        <div className={error ? `section-wrapper contact-info contact-info-${props.index} error-section` : `section-wrapper contact-info contact-info-${props.index}`}>
            <div className="section-items">
                <div className="row-section">
                    <EditorComponent required={true} forceValidate={ forceValidate } setFunction={ val => updateEditor(val) } value={props.data.editor} refreshComponent={refreshComponent} />
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;
