import React, { useState, useEffect, useRef } from 'react'
import Input from '../../../../components/input/Input'
import Textarea from '../../../../components/input/Textarea';
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
import Sections from '../../../../components/sections/Sections';
import { useSelector, useDispatch } from 'react-redux'
import Get from '../../../../services/Get';
import Select from '../../../../components/select/Select';
import Categories from './../../../../components/boxes/Categories';
import filterSectionsData from './../../../../services/FilterSectionsData';
import DateTimeRangerPicker from '../../../../components/boxes/DateTimeRangerPicker';
import ImagePost from './../../../../components/boxes/ImagePost';
import StatusPage from './../../../../components/boxes/StatusPage';
import PageTitle from './../../../../components/page-title/PageTitle';
import Button from './../../../../components/button/Button';
import readTextNvda from './../../../../services/readTextNvda';
import SaveIcon from './../../../../svg-components/SaveIcon';
import Editor from './../../../../components/editor/Editor';
import MediaLoad from './../../../../components/media-load/MediaLoad';
import SelectItems from './../../../../components/select-items/SelectItems';
import Gallery from './../../../../components/gallery/Gallery';

const NewPage = (props) => {
    let [ load, setLoad ] = useState(false)
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ title, setTitle ] = useState('');
    let [ sneakpeak, setSneakpeak ] = useState('');
    let [ disableTopDate, setDisableTopDate ] = useState(false);
    let [ important, setImportant ] = useState(false);
    let [ status, setStatus ] = useState("published");
    let [ sections, setSections ] = useState([])
    let [ resetLoad, setResetLoad ] = useState(0);
    let [ validateSections, setValidateSections ] = useState(0);
    let [ btnClickedNumber, setBtnClickedNumber ] = useState(0);
    let language = useSelector(state => state.lang);
    let [ langID, setLangID ] = useState();
    let categories = useSelector(state => state.categories);
    const dispatch = useDispatch();
    let [ resultPage, setResultPage ] = useState();
    let postType = typeof window !== "undefined" && (window.location.pathname).split("/");postType = postType[2]
    let countData = useSelector(state => state.countData);
    let [ dateRange, setDateRange ] = useState([])
    let [ previewImage, setPreviewImage ] = useState(false);
    let [ lectorData, setLectorData ] = useState(false);
    let [ categoriesArray, setCategoriesArray ] = useState([]);
    let [ seoDescription, setSeoDescription ] = useState('');
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ lastClickedRadioCategory, setLastClickedRadioCategory ] = useState(false); 
    let [ dimensions, setDimensions ] = useState('');
    let [ line, setLine] = useState("");
    let [ width, setWidth ] = useState("");
    let [ height, setHeight ] = useState("");
    let [ depth, setDepth ] = useState("");
    let [ coloursTechnicalDesc, setColoursTechnicalDesc ] = useState([]);
    let [ technicalDescription, setTechnicalDescription ] = useState("");
    let [ collectionDesc, setCollectionDesc ] = useState("");
    let [ TD, setTD ] = useState(false); 
    let [ brandsList, setBrandsList ] = useState(false);
    let [ chooseTD, setChooseTD ] = useState([]);
    let [ chooseBrands, setChooseBrands ] = useState([]);
    let [ indexProduct, setIndexProduct ] = useState(false);
    let [ price, setPrice ] = useState(false);
    let [ langsProduct, setLangsProduct ] = useState("");
    let [ city, setCity ] = useState("");
    let [ galleryRealization, setGalleryRealization ] = useState([]);
    let [ sneakpeakImage, setSneakpeakImage ] = useState(false);

    useEffect(() => {
        if(!load && language && postType) {
            setLangID(language.id)
            if(postType == "collections") {
                getPages("technical-description");
            }
            if(postType == "products") {
                getPages("brands");
            }
            setLoad(true);
        }
    }, [load, language, postType])

    let validateForm = async() => {
        await setValidateSections(generateRandomNumber(1, 99999999)) // run validate sections
        await setForceValidate(true);
        await wait(300);
        await setForceValidate(false);
        let errors = await checkErrors();
        if ( !errors ) {
            await save();
        } 
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll('.sections-component .error');
        let elementsCard = document.querySelectorAll('.new-page-container .error');

        if( elements.length > 0 ) {
            elements[0].closest('.content').classList.add("active");
            readTextNvda("sections");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else if(elementsCard.length > 0) {
            readTextNvda('new-page');
            let topElement = elementsCard[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const generateRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min);
    };

    const save = () => {
        setBlockedBtn(true);
        setBtnClickedNumber(btnClickedNumber + 1)
        let sendSections = filterSectionsData(sections)
        axios({
            method: 'post',
            url: `${ApiUrl()}/savePage`,
            data: {
                token: localStorage.getItem('token'),
                title: title,
                sneakpeak: sneakpeak,
                sections: sendSections,
                userID: localStorage.getItem('userId'),
                postType: postType,
                relation: Get('relation'),
                language: Get('lang') ? Get('lang') : langID,
                categoriesItems: categoriesArray,
                dateRange: dateRange,
                previewImage: previewImage ? previewImage.id : false,
                lector: lectorData ? lectorData.id : false,
                disableTop: disableTopDate,
                important: important,
                status: status,
                seoDescription: seoDescription,
                dimensions, dimensions,
                line: line,
                width: width,
                height: height,
                depth: depth,
                coloursTechnicalDesc: coloursTechnicalDesc,
                technicalDescription: technicalDescription,
                collectionDesc: collectionDesc,
                chooseTD: chooseTD,
                chooseBrands: chooseBrands,
                indexProduct: indexProduct,
                price: price,
                langsProduct: langsProduct,
                city: city,
                galleryRealization: galleryRealization,
                sneakpeakImage: (sneakpeakImage ? sneakpeakImage.id : false),
                edit: false
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status = "success") {
                setBlockedBtn(false);
                incrementCountData()
                props.history.push(`/dashboard/${postType}/page/${res.pageID}`)
            }else{
                setBlockedBtn(false);
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
            }
        })
    }

    const incrementCountData = () => {
        let count = countData[postType];
        let newCountData = countData;
        newCountData[postType] = ++count;
        dispatch({ type: 'COUNT_DATA', value: newCountData })
    }

    const insertCategories = (data) => {
        let newCategoryArray = [];
        data && data.map(item => {
            newCategoryArray = [...newCategoryArray, item];
        })
        setCategoriesArray(newCategoryArray);
    }

    const disableTopOptions = [
        {
            value: "true",
            text: "wyłącz"
        },
        {
            value: "false",
            text: "włącz"
        },
    ]

    const importantOptions = [
        {
            value: "true",
            text: "wyróżnij"
        },
        {
            value: "false",
            text: "nie wyróżniaj"
        },
    ]

    let keyPressed = (e, action) => {
        if ( e.code === "Enter" ) {
            action();
        }
    }

    const removeItemColour = (id) => {
        let newData = coloursTechnicalDesc.filter((item, i) => item.image.id === id ? false : item);
        setColoursTechnicalDesc(newData);
    }

    const addItemColour = (item) => {
        let newData = [...coloursTechnicalDesc, {
            image: item,
        }]
        setColoursTechnicalDesc(newData);
    }

    const getPages = (postType) => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/pagesList?langID=${language.id}&postType=${postType}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(postType == "technical-description") {
                setTD(res.data);
            }else if(postType == "brands") {
                setBrandsList(res.data)
            }
        })
    }

    return (
        <div className="container new-page-container">
            <PageTitle title={"Utwórz"} subTitle="Uzupełnij dane" />
            <div className="wrapper">
                <div className="form">
                    <div className="input-row">
                        <Input  name="title"
                            required={ true }
                            label="Tytuł strony"
                            type="text" 
                            value={ title }
                            disabled={ false } 
                            setFunction={ (val) => setTitle(val) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    {(postType == "brands" || postType == "human-touch") &&
                    <>
                        <div className="input-row">
                            <Editor required={true} forceValidate={ forceValidate } setFunction={ val => setSneakpeak(val) } value={ sneakpeak } label="Zajawka na liste" />
                        </div>
                        <div className="input-row">
                            <MediaLoad required={true} forceValidate={ forceValidate } setFunction={ val => setSneakpeakImage(val) } value={ sneakpeakImage } label="Zdjęcie zajawka na liste" />
                        </div>
                    </>
                    }
                    {postType !== "realizations" && postType !== "accessories" && postType !== "collections" && postType !== "marketing-support" &&
                        <div className="input-row">
                            <Textarea  name="seo_description"
                                label="SEO - opis"
                                value={ seoDescription }
                                setFunction={ (val) => setSeoDescription(val) } 
                                class="btn-primary btn-simple" />
                        </div>
                    }
                    {postType == "realizations" &&
                        <>
                            <div className="input-row">
                                <Input  name="city"
                                    required={ true }
                                    label="Miasto"
                                    type="text" 
                                    value={ city }
                                    disabled={ false } 
                                    setFunction={ (val) => setCity(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Gallery 
                                    value={ galleryRealization }
                                    setFunction={ (val) => setGalleryRealization(val) }
                                    forceValidate={ forceValidate }
                                />
                            </div>
                        </>
                    }
                    {postType == "marketing-support" &&
                        <>
                            <div className="input-row">
                                <Input  name="width"
                                    required={ true }
                                    label="Szerokość"
                                    type="text" 
                                    value={ width }
                                    disabled={ false } 
                                    setFunction={ (val) => setWidth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="depth"
                                    required={ true }
                                    label="Głębokość"
                                    type="text" 
                                    value={ depth }
                                    disabled={ false } 
                                    setFunction={ (val) => setDepth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="height"
                                    required={ true }
                                    label="Wysokość"
                                    type="text" 
                                    value={ height }
                                    disabled={ false } 
                                    setFunction={ (val) => setHeight(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="index"
                                    required={ true }
                                    label="Index"
                                    type="number" 
                                    value={ indexProduct }
                                    disabled={ false } 
                                    setFunction={ (val) => setIndexProduct(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="price"
                                    required={ true }
                                    label="Cena"
                                    type="number" 
                                    value={ price }
                                    disabled={ false } 
                                    setFunction={ (val) => setPrice(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="langs"
                                    required={ true }
                                    label="Języki (zapisywać po średniku pl;en)"
                                    type="text" 
                                    value={ langsProduct }
                                    disabled={ false } 
                                    setFunction={ (val) => setLangsProduct(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                        </>
                    }
                    {postType == "collections" &&
                    <>
                        <div className="input-row">
                            <Textarea  name="collection_desc"
                                label="Opis kolekcji"
                                value={ collectionDesc }
                                setFunction={ (val) => setCollectionDesc(val) } 
                                class="btn-primary btn-simple" />
                        </div>
                        {TD && TD.length > 0 &&
                            <div className="select-boxes">
                                <SelectItems
                                    label="Opisy techniczne"
                                    data={ TD } 
                                    value={ chooseTD }
                                    handle={ (val) => setChooseTD(val) } 
                                    forceValidate={ forceValidate } 
                                    column="xs-12" />
                            </div>
                        }
                    </>
                    }
                    {postType == "products" &&
                        <>
                            {brandsList && brandsList.length > 0 &&
                                <div className="select-boxes">
                                    <SelectItems
                                        label="Marka"
                                        limit={1}
                                        data={ brandsList } 
                                        value={ chooseBrands }
                                        handle={ (val) => setChooseBrands(val) } 
                                        forceValidate={ forceValidate } 
                                        column="xs-12" />
                                </div>
                            }
                        </>
                    }
                    {postType == "accessories" &&
                        <div className="input-row">
                            <Input  name="dimensions"
                                required={ true }
                                label="Wymiary"
                                type="text" 
                                value={ dimensions }
                                disabled={ false } 
                                setFunction={ (val) => setDimensions(val) } 
                                forceValidate={ forceValidate }
                                class="btn-primary btn-simple" />
                        </div>
                    }
                    {postType == "technical-description" &&
                        <>
                            <div className="input-row">
                                <Input  name="line"
                                    required={ true }
                                    label="Line"
                                    type="text" 
                                    value={ line }
                                    disabled={ false } 
                                    setFunction={ (val) => setLine(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="width"
                                    required={ true }
                                    label="Szerokość"
                                    type="text" 
                                    value={ width }
                                    disabled={ false } 
                                    setFunction={ (val) => setWidth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="depth"
                                    required={ true }
                                    label="Głębokość"
                                    type="text" 
                                    value={ depth }
                                    disabled={ false } 
                                    setFunction={ (val) => setDepth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="height"
                                    required={ true }
                                    label="Wysokość"
                                    type="text" 
                                    value={ height }
                                    disabled={ false } 
                                    setFunction={ (val) => setHeight(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            {/* <div className="input-row">
                                <Editor required={true} forceValidate={ forceValidate } setFunction={ val => setTechnicalDescription(val) } value={ technicalDescription } label="Opis" />
                            </div> */}
                            <div className="input-row">
                                <MediaLoad setFunction={ val => addItemColour(val)} value={ coloursTechnicalDesc } only="image" type="gallery" items={ coloursTechnicalDesc } removeItem={(id) => removeItemColour(id)} label="Kolory" />
                            </div>
                        </>
                    }
                    <div className="input-row">
                    {langID &&
                        <Sections setSections={(data) => setSections(data)} sections={sections} resetLoad={resetLoad} validateSections={validateSections} edit={false} langID={Get('lang') ? Get('lang') : langID} postType={postType} />
                    }
                    </div>
                    <div className="input-row save-row">
                        <Button title="Zapisz" setFunction={() => validateForm() } />
                    </div>
                </div>
                <div className="boxes">
                    {/* {postType == "articles" && (lastClickedRadioCategory.name == "Wydarzenia" || lastClickedRadioCategory.name == "Event") &&
                        <DateTimeRangerPicker date={dateRange} setFunction={(val) => setDateRange(val)} />
                    } */}
                    <StatusPage status={status} updateStatus={(val) => setStatus(val) } />
                    {postType == "accessories" && langID && categories && categories.length > 0 && categories.map((cat, index) => {
                        if(cat.name == "Kategorie akcesorii") {
                            return (
                                <Categories languagePageID={Get('lang') ? Get('lang') : langID} postType={postType} meta={resultPage && resultPage.meta} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={ validateSections } clickedItem={(item) => setLastClickedRadioCategory(item)} />
                            )
                        }
                    })}
                    <ImagePost img={previewImage} setFunction={(val) => setPreviewImage(val)} forceValidate={ validateSections } />
                    <div className="btn-fix" tabIndex="0" aria-label="Zapisz strone" onClick={() => validateForm()} onKeyDown={(e) => keyPressed(e, validateForm)}>
                        <SaveIcon />
                    </div>
                </div>
            </div>
        </div>       
    );
}

export default NewPage;
