import React from 'react'


const CheckIcon = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288.94 203.26"><title>Zasób 1</title><g id="Warstwa_2" data-name="Warstwa 2"><g id="Capa_1" data-name="Capa 1"><path id="Check" d="M285.38,3.53a12.21,12.21,0,0,0-17.18,0L96.31,174.27,20.73,99.77a12.22,12.22,0,0,0-17.17,0,11.94,11.94,0,0,0,0,17l84.19,83a12.37,12.37,0,0,0,17.2,0L285.38,20.54a11.93,11.93,0,0,0,0-17C280.64-1.18,290.13,8.22,285.38,3.53Z"/></g></g></svg>
  )

}
export default CheckIcon
