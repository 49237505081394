import React, { useState, useEffect } from 'react'
import close from './../../../images/svg/close.svg';
import axios from 'axios';
import ApiUrl from '../../../services/ApiUrl';
import ReactPlayer from 'react-player/lazy'
import FocusTrap from 'focus-trap-react';
import Input from './../../../components/input/Input';
import Button from './../../../components/button/Button';
import CloseIcon from './../../../svg-components/CloseIcon';

const MediaItemPopup = ( props ) => {
    let [ alt, setAlt ] = useState([]);
    let [ name, setName ] = useState('');
    let [ updateAltStatus, setUpdateAltStatus ] = useState(false);
    let [ copyTextBtn, setCopyTextBtn ] = useState("skopiuj URL");

    useEffect(() => {
        if(alt.length == 0) {
            setAlt(props.data.alts)
            setName(props.data.name);
        }
    }, [props.page])

    const updateImage = (column) => {
        if(updateAltStatus || name !== props.data.name) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/updateImageLibrary`,
                data: {
                    column: column,
                    imageID: props.data.id,
                    alt: alt,
                    name: name,
                    token: localStorage.getItem('token'),
                    userID: localStorage.getItem('userId'),
                },
            }).then(response => {
                let res = JSON.parse(response.request.response);
                setUpdateAltStatus(false);
            })
            let newGallery = props.gallery;
            newGallery = newGallery.map((galleryItem, index) => index == props.openIndex ? {
                ...galleryItem,
                alts: alt,
                name: name,
            } : galleryItem)
            props.updateGalleryItems(newGallery);
        }
    }
    const removeImage = () => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/removeImageLibrary`,
            data: {
                imageID: props.data.id,
                token: localStorage.getItem('token')
            },
        }).then(response => {
            let res = JSON.parse(response.request.response);
            props.refreshGallery();
            props.setIndex(999999);
        })
    }

    const updateAlt = (value, index) => {
        let copyAlt = alt;
        let newAltsValues = copyAlt.map((item, i) => i == index ? { language: item.language, value: value, id: item.id, update: true } : item);
        setAlt(newAltsValues);
        setUpdateAltStatus(true);
    }

    const onKeyPressCloseModal = (e) => {
        if(e.key === 'Enter') {
            closeModal();
        }
    }

    const closeModal = () => {
        props.setIndex(999999); 
        updateImage(["alt", "name"])
    }

    const copy = async(copyUrl) => {
        await setCopyTextBtn("Skopiowano !");
        const myTimeout = setTimeout(changeTextBtn, 3000);

        function changeTextBtn () {
            setCopyTextBtn("skopiuj URL");
            clearTimeout(myTimeout);
        }
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(copyUrl);
        } else {
            return document.execCommand('copy', true, copyUrl);
        }
    }

    return (
        <FocusTrap>
            <div className="media-item-popup">
                <div className={"image-box-popup "  + (props.data.src.split('.').pop().substring(0, 3))}>
                    {(props.data.type == "image" || props.data.type == "vector") && 
                        <img src={props.data.sizes.small} alt={props.data.alts[0].value} />
                    }
                    {(props.data.type == "lector" || props.data.type == "video" || props.data.type == "video-url") &&
                        <ReactPlayer playing controls url={props.data.sizes.small} />
                    }
                    {(props.data.type == "attachment") &&
                        <iframe width="100%" height="100%" frameborder="0" src={`https://docs.google.com/gview?url=${props.data.sizes.small}&embedded=true`}></iframe>
                    }
                </div>
                <div className="information-img">
                    <div className="exit" tabIndex="0" role="button" aria-label="Zamknij" onKeyDown={(e) => onKeyPressCloseModal(e)} onClick={() => closeModal() }>
                        {/* <img src={close} alt="zamknij" onClick={() => closeModal() } /> */}
                        <CloseIcon />
                    </div>
                    <div className="info">
                        <Input  
                            setFunction={ val => setName(val) }
                            value={ name }
                            placeholder={ "Nazwa" }
                            label={ "Nazwa" }
                            regExp={ /.*/ }
                            minChar={ 3 } 
                            class="btn-tertiary btn-simple"
                        />
                    </div>
                    {alt && alt.length > 0 && alt.map((item, index) => (
                    <div className="info">
                        <Input  
                            setFunction={ val => updateAlt(val, index) }
                            value={ item.value }
                            placeholder={ `Alt ${item.language}` }
                            label={ `Alt ${item.language}` }
                            regExp={ /.*/ }
                            minChar={ 3 } 
                            class="btn-tertiary btn-simple"
                        />
                    </div>
                    ))}
                    <div className="info">
                        <span className="label">Data przesłania </span>
                        <span className="value">{ props.data.created_at }</span>
                    </div>
                    {props.data.weight &&
                        <div className="info">
                            <span className="label">Waga </span>
                            <span className="value">{ props.data.weight }</span>
                        </div>
                    }
                    {props.data.size &&
                        <div className="info">
                            <span className="label">Rozmiar(wgrany) </span>
                            <span className="value">{ props.data.size }</span>
                        </div>
                    }
                    <div className="info">
                        <span className="label">Kto wgrał </span>
                        <span className="value">{ props.data.user_id.login }</span>
                    </div>
                    <div className="info">
                        <Button class="btn-primary small" title={copyTextBtn} setFunction={ () => copy(props.data.src) } />
                    </div>
                    <div className="info">
                        <Button title="Usuń" setFunction={ () => removeImage() } />
                    </div>
                </div>
            </div>       
        </FocusTrap>
    );
}

export default MediaItemPopup;
