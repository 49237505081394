import React from 'react';

const Arrow = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="12px" height="19px">
            <path fill-rule="evenodd"  fill="rgb(44, 82, 153)"
            d="M11.450,16.594 L4.901,9.732 L11.450,2.870 L9.434,0.763 L0.855,9.732 L9.434,18.702 L11.450,16.594 Z"/>
        </svg>
    )
}

export default Arrow;