import React, { useState, useEffect } from 'react'
import Input from './../../../../components/input/Input';
import axios from 'axios';
import ApiUrl from './../../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'
import PageTitle from './../../../../components/page-title/PageTitle';
import Button from './../../../../components/button/Button';
import readTextNvda from './../../../../services/readTextNvda';

let valueTranslatesGlobal = [];
const Translate = (props) => {
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false)
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ metaKey, setMetaKey ] = useState('');
    let [ valueTranslates, setValueTranslates ] = useState([]);
    // let [ translates, setTranslates ] = useState()
    let languages = useSelector(state => state.languages);
    let pageID = typeof window !== "undefined" && (window.location.pathname).split("/");pageID = pageID[pageID.length -1]
    let [forceValidate, setForceValidate ] = useState(false);

    useEffect(() => {
        if (!load) {
            ;(async function() {
                await getTranslate();
                await setLoad(true);
            })()
        }
    },[load]);

    const getTranslate = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getTranslateById?pageID=${pageID}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response).data[0];
            getValueTranslates(res)
        })
    }

    const getValueTranslates = (item) => {
        let existsLanguages = [];
        const saveData = (result) => {
            setMetaKey(result.meta_key)
            let translates = []
            result.translates.map(item => {
                existsLanguages = [...existsLanguages, item.language]
                translates = [...translates, {
                    ...item,
                    error: false
                }]
            })
            setValueTranslates(translates);
            valueTranslatesGlobal = translates;
        }
        saveData(item)
        //check new language
        languages.map(lang => {
            if(!existsLanguages.includes(lang.shortcode)) {
                let newLang = {
                    id: 0,
                    language: lang.shortcode,
                    error: false,
                    value: '',
                }
                setValueTranslates([...valueTranslatesGlobal, newLang]);
                valueTranslatesGlobal = [...valueTranslatesGlobal, newLang];
            }
        })
    }

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(!errors) {
            save();
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll('.translate-container .error');

        if( elements.length > 0 ) {
            readTextNvda("translate");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const save = () => {
        if(!blockedBtn) {
            setBlockedBtn(true);
            axios({
                method: 'post',
                url: `${ApiUrl()}/saveTranslate`,
                data: {
                    pageID: pageID,
                    token: localStorage.getItem('token'),
                    valueTranslates: valueTranslates,
                    metaKey: metaKey,
                    userID: localStorage.getItem('userId'),
                    edit: true,
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status = "success") {
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Zaaktualizowano tłumaczenie</p>` }) 
                }else{
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                }
            })
        }
    }

    const updateValueTranslates = (val, index, label) => {
        let copyValueTranslates = valueTranslatesGlobal;
        copyValueTranslates = copyValueTranslates.map((item, i) => i == index ? ({...item, [label]: val}) : item);
        setValueTranslates(copyValueTranslates)
        valueTranslatesGlobal = copyValueTranslates;
    }

    return (
        <div className="container translate-container">
            <div className="wrapper">
                <PageTitle title="Edytuj tłumaczenie" />
                <div className="form">
                    <div className="input-row">
                        <Input  name="meta-key"
                            required={ true }
                            label="Meta key"
                            type="text" 
                            value={ metaKey }
                            disabled={ false } 
                            setFunction={ (val) => setMetaKey(val.replace(' ', '_')) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    {valueTranslates && valueTranslates.length > 0 && valueTranslates.map((tran, index) => (
                        <div className="input-row">
                            <Input name="value"
                                required={true}
                                label={`Wartość ${tran.language}`}
                                type="text" 
                                value={ tran.value }
                                disabled={ false } 
                                setFunction={ (val) => updateValueTranslates(val, index, "value") } 
                                forceValidate={ forceValidate }
                                class="btn-primary btn-simple" />
                        </div>
                    ))}
                    <div className="input-save">
                        <Button title="Zapisz" setFunction={() => validate()} class="btn-secondary" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Translate;
