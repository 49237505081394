import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ApiUrl from '../../../services/ApiUrl';
import MediaLoad from './../../../components/media-load/MediaLoad';
import Input from './../../../components/input/Input';
import Editor from './../../../components/editor/Editor';
import SelectItems from './../../../components/select-items/SelectItems';
import { useSelector } from 'react-redux';
import Arrow from './../../../svg-components/Arrow';
import TrashIcon from './../../../svg-components/TrashIcon';
import Button from './../../../components/button/Button';
import Loader from './../../../components/loader/Loader';
import Popup from './../../../components/popup/Popup';
import { useDispatch } from 'react-redux';
import readTextNvda from './../../../services/readTextNvda';
import PageTitle from './../../../components/page-title/PageTitle';

const Settings = () => {
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false);
    let [ loader, setLoader ] = useState(true);
    let [ data, setData ] = useState([]);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ random, setRandom ] = useState()
    let [ removeList, setRemoveList ] = useState([]);
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ pages, setPages ] = useState([]);
    let lang = useSelector(state => state.lang);
    let [ users, setUsers ] = useState([]);
    let [ openBoxes, setOpenBoxes ] = useState([]);
    let [ popupText, setPopupText ] = useState(false);

    useEffect(() => {
        if (!load) {
            ;(async function() {
                await setLoader(true);
                await getSettings();
                await getPages();
                await getUsers();
                await setLoad(true);
            })()
        }
    },[load]);

    useEffect(() => {
        if(lang && load) {
            setLoad(false);
        }
    }, [lang])

    const getSettings = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getSettings?langID=${lang.id}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response).data;
            setData(res);
        })
    }

    const getPages = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/pagesList?langID=${lang.id}&postType=pages`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setPages(res.data);
        })
    }

    const getUsers = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/usersList`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setUsers(res.data);
            setLoader(false);
        })
    }

    const updateFields = (val, indexData, indexItem, label) => {
        let newData = data;
        let copyData = newData[indexData].data
        copyData.map((item, i) => i == indexItem ? (item[label] = val, item['update'] = true) : item);
        newData[indexData].data = copyData;
        setData(newData);
        setRandom(Math.floor(Math.random() * (99999999999999 - 1) + 1));
    }

    const addItem = (indexData) => {
        let newData = data;
        let copyData = newData[indexData].data
        if(newData[indexData].type == "LOGOS_MENUS" || newData[indexData].type == "LOGOS_UNDER_FOOTER" || newData[indexData].type == "LOGOS_FOOTER" || newData[indexData].type == "LOGOS_UNDER_FOOTER_MOBILE" || newData[indexData].type == "CHAT_REGULATION") {
            copyData = [...copyData, {
                value: '',
                update: true,
                id: 'new',
                settingsID: newData[indexData].id
            }]
        }else if(newData[indexData].type == "SOCIALS" || newData[indexData].type == "ICONS_MOBILE_APP") {
            copyData = [...copyData, {
                value: '',
                update: true,
                id: 'new',
                settingsID: newData[indexData].id,
                link: ''
            }]
        }else if(newData[indexData].type == "ICONS") {
            copyData = [...copyData, {
                value: '',
                update: true,
                id: 'new',
                settingsID: newData[indexData].id,
                meta_key: newData[indexData].meta_key
            }]
        }else if(newData[indexData].type == "MAIN_PAGE" || newData[indexData].type == "MATERIALS_PAGE" || newData[indexData].type == "MAIN_EMAIL" || newData[indexData].type == "HEADER_FOOTER_LINKS" || newData[indexData].type == "NOTIFICATION_AUTOTEST" || newData[indexData].type == "NOTIFICATION_CHAT" ) {
            copyData = [...copyData, {
                value: '',
                update: true,
                id: 'new',
                settingsID: newData[indexData].id,
            }]
        }
        newData[indexData].data = copyData
        setData(newData);
        setRandom(Math.floor(Math.random() * (99999999999999 - 1) + 1));
    }

    const removeItem = (indexData, indexItem) => {
        let newData = data;
        let copyData = newData[indexData].data
        const removeListAction = (item) => {
            if(item && item.id) {
                let copyRemoveList = removeList;
                copyRemoveList = [...copyRemoveList, item.id];
                setRemoveList(copyRemoveList)
            }
            return false;
        }
        copyData = copyData.filter((item, i) => i == indexItem ? removeListAction(item) : item);
        newData[indexData].data = copyData;
        setData(newData);
        setRandom(Math.floor(Math.random() * (99999999999999 - 1) + 1));
    }

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            save();
            setErrors(false);
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll('.settings-container .error');
        if( elements.length > 0 ) {
            readTextNvda('settings');
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const save = () => {
        if(!blockedBtn) {
            setBlockedBtn(true);
            axios({
                method: 'post',
                url: `${ApiUrl()}/saveSettings`,
                data: {
                    token: localStorage.getItem('token'),
                    userID: localStorage.getItem('userId'),
                    removeList: removeList,
                    langID: lang.id,
                    data: data
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status = "success") {
                    setData(res.data)
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Zmiany zostały zapisane</p>` }) 
                }else{
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                }
            })
        }
    }

    const handleKeyDown = (e, functionName, functionValueFirst = false, functionValueSecond = false) => {
        if (e.key === 'Enter') {
            functionName(functionValueFirst, functionValueSecond)
        }
    }

    const toggleBox = (index) => {
        let status = openBoxes.includes(index);
        if(status) {
            let newOpened = openBoxes;
            let spliceIndex = newOpened.indexOf(index);
            newOpened = newOpened.filter((item, i) => i !== spliceIndex);
            setOpenBoxes(newOpened)
        }else{
            let newOpened = openBoxes;
            newOpened = [...newOpened, index];
            setOpenBoxes(newOpened);
        }
    }

    return (
        <div className="container settings-container">
            <PageTitle title="Ustawienia" />
            <div className="settings">
                {loader &&
                    <div className="loader-wrapper">
                        <div className="loader">
                            <Loader />
                        </div>
                    </div>
                }
                {popupText &&
                    <Popup 
                        close={() => setPopupText(false)} 
                        editor={popupText} 
                    />
                }
                {data && data.map((item, indexData) => (
                    <div className={"setting" + (openBoxes.includes(indexData) ? " active" : "")}>
                        <div className="header"  
                            onClick={() => toggleBox(indexData)} 
                            onKeyDown={(e) => handleKeyDown(e, toggleBox, indexData)}
                            tabIndex="0"
                            role="button"
                            aria-label={openBoxes.includes(indexData) ? `Zamknij ${item.name}` : `Otwórz ${item.name}`}>
                            <h2>{item.name}</h2>
                            <div className="svg-icon">
                                <Arrow />
                            </div>
                        </div>
                        <div className="content">
                            <div className="boxes">
                                {item.data && item.data.map((it, indexItem) => (
                                    <div className={"box" +
                                        (item.type == "MAIN_PAGE" || item.type == "MATERIALS_PAGE" || item.type == "HEADER_FOOTER_LINKS" || item.type == "NOTIFICATION_AUTOTEST" || item.type == "NOTIFICATION_CHAT" ? " select-items-box" : "") + 
                                        (item.type == "MAIN_EMAIL" ? " min-height-box" : "") + (item.type == "CHAT_REGULATION" ? " editor-box" : "")}>
                                        <div className="remove-item" 
                                            onClick={() => removeItem(indexData, indexItem)} 
                                            tabIndex="0" 
                                            role="button" 
                                            aria-label={`Usuń box`}
                                            onKeyDown={(e) => handleKeyDown(e, removeItem, indexData, indexItem)}>
                                            <TrashIcon />
                                            <p>Usuń</p>
                                        </div>
                                        {(item.type == "MAIN_PAGE" || item.type == "MATERIALS_PAGE" || item.type == "HEADER_FOOTER_LINKS") &&
                                            <div className="label-item">
                                                <label>Strony</label>
                                            </div>
                                        }
                                        {item.type !== "HEADER_FOOTER_LINKS" && item.type !== "MAIN_PAGE" && item.type !== "MATERIALS_PAGE" && item.type !== "MAIN_EMAIL" && item.type !== "CHAT_REGULATION" && item.type !== "NOTIFICATION_AUTOTEST" && item.type !== "NOTIFICATION_CHAT" &&
                                            <div className="row-section row-image">
                                                <MediaLoad forceValidate={ forceValidate } setFunction={ val => updateFields(val, indexData, indexItem, 'value') } value={it.value} disableBtn={true} />
                                            </div>
                                        }
                                        {(item.type == "SOCIALS" || item.type == "ICONS_MOBILE_APP" ) && 
                                            <div className="row-section row-input">
                                                <Input  required={ true }
                                                    setFunction={ val => updateFields(val, indexData, indexItem, 'link') }
                                                    value={it.link}
                                                    label={ 'Link' }
                                                    regExp={ /.*/ }
                                                    minChar={ 3 } 
                                                    class="btn-primary btn-simple"
                                                    forceValidate={ forceValidate } 
                                                />
                                            </div>
                                        }
                                        {item.type == "MAIN_EMAIL" &&
                                            <div className="row-section row-input">
                                                <Input  required={ true }
                                                    setFunction={ val => updateFields(val, indexData, indexItem, 'value') }
                                                    value={it.value}
                                                    label={ 'Email' }
                                                    regExp={ /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ }
                                                    minChar={ 3 } 
                                                    class="btn-primary btn-simple"
                                                    forceValidate={ forceValidate } 
                                                />
                                            </div>
                                        }
                                        {item.type == "ICONS" &&
                                            <div className="row-section row-input">
                                                <Input  required={ true }
                                                    setFunction={ val => updateFields(val, indexData, indexItem, 'meta_key') }
                                                    value={it.meta_key}
                                                    label={ 'Meta Key' }
                                                    regExp={ /.*/ }
                                                    minChar={ 3 } 
                                                    class="btn-primary btn-simple"
                                                    forceValidate={ forceValidate } 
                                                />
                                            </div>
                                        }
                                        {(item.type == "MAIN_PAGE" || item.type == "MATERIALS_PAGE" || item.type == "HEADER_FOOTER_LINKS") &&
                                            <div className="row-section row-select-items">
                                                {pages && pages.length > 0 &&
                                                    <SelectItems
                                                        // label="Strony"
                                                        data={ pages } 
                                                        value={ it.value }
                                                        handle={ (val) => updateFields(val, indexData, indexItem, 'value') } 
                                                        forceValidate={ forceValidate } 
                                                        limit={1}
                                                        column="xs-12" />
                                                }
                                            </div>
                                        }
                                        {(item.type == "NOTIFICATION_AUTOTEST" || item.type == "NOTIFICATION_CHAT") &&
                                            <div className="row-section row-select-items">
                                                {users && users.length > 0 &&
                                                    <SelectItems
                                                        label="Użytkownicy"
                                                        data={ users } 
                                                        value={ it.value }
                                                        handle={ (val) => updateFields(val, indexData, indexItem, 'value') } 
                                                        forceValidate={ forceValidate } 
                                                        column="xs-12" />
                                                }
                                            </div>
                                        }
                                        {(item.type == "CHAT_REGULATION") &&
                                            <div className="row-section">
                                                <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateFields(val, indexData, indexItem, 'value') } value={it.value} />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            {((item.type == "HEADER_FOOTER_LINKS" && item.data && (item.data).length < 2) || item.type !== "HEADER_FOOTER_LINKS") &&
                            ((item.type == "MAIN_PAGE" && item.data && (item.data).length == 0) || item.type !== "MAIN_PAGE") &&
                            ((item.type == "MATERIALS_PAGE" && item.data && (item.data).length == 0) || item.type !== "MATERIALS_PAGE") &&
                            ((item.type == "MAIN_EMAIL" && item.data && (item.data).length == 0) || item.type !== "MAIN_EMAIL") &&
                            ((item.type == "CHAT_REGULATION" && item.data && (item.data).length == 0) || item.type !== "CHAT_REGULATION") &&
                            ((item.type == "NOTIFICATION_AUTOTEST" && item.data && (item.data).length == 0) || item.type !== "NOTIFICATION_AUTOTEST") &&
                            ((item.type == "NOTIFICATION_CHAT" && item.data && (item.data).length == 0) || item.type !== "NOTIFICATION_CHAT") &&
                                <div className="add-item-in-section">
                                      <Button title="Dodaj kolejne" class="btn-secondary" icon="add" setFunction={() => addItem(indexData)} />
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
            <div className="save-data">
                <Button title="Zapisz zmiany" class="btn-secondary" setFunction={() => validate()} />
            </div>
        </div>
    );
}

export default Settings;