import React, { useState, useEffect } from 'react'
import Select from './../select/Select';

const StatusPage = ( props ) => {
    let [ reset, setReset ] = useState(false);
    let [ status, setStatus ] = useState(false);

    useEffect(() => {
        if(props.status && props.status !== status) {
            setStatus(props.status);
            resetSelect();
        }
    }, [props.status])

    const resetSelect = async() => {
        await setReset(true);
        await wait(300);
        await setReset(false);
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    const options = [
        {
            value: "published",
            text: "opublikowany"
        },
        {
            value: "unpublished",
            text: "nieopublikowany"
        },
    ]

    return (
        <div className="status-page settings-box">
            <div className="header">
                <span>Status</span>
            </div>
            <div className="content">
                <Select name="status"
                    data={ options } 
                    value={ status }
                    handle={ (val) => { props.updateStatus(val); setStatus(val) } } 
                    reset={ reset }
                    class="btn-primary btn-simple" />
            </div>
        </div>
    );
}

export default StatusPage;
