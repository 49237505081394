import React, { useState, useEffect } from 'react'
import MediaLoad from './../../../media-load/MediaLoad';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from './../../../button/Button';
import Editor from '../../../editor/Editor';
import Select from './../../../select/Select';

let validateNumber = 0;
const FilesDownload = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                boxes: [
                    {
                        text: '',
                        class: '',
                        img: ''
                    },
                ],
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data.boxes) {
                props.onChange({
                    boxes: [
                        {
                            text: '',
                            class: '',
                            img: ''
                        },
                    ],
                }, props.index)
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(props.data.boxes);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        saveData(items);
    }

    const updateFields = (val, index, label) => {
        let newData = props.data.boxes;
        newData.map((item, i) => i == index ? item[label] = val : item);
        saveData(newData);
    }

    const addItem = () => {
        let newData = [...props.data.boxes, {
            text: '',
            class: '',
            img: ''
        }]
        saveData(newData);
    }

    const removeItem = (index) => {
        let newData = props.data.boxes.filter((item, i) => i == index ? false : item);
        saveData(newData);
    }

    const saveData = (data) => {
        props.onChange({
            ...props.data,
            boxes: data
        }, props.index)
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.environment-box-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    const rolledSection = (index) => {
        if(typeof window !== "undefined") {
            let sections = document.querySelectorAll(`.environment-box-${props.index} .environment-content`);
            sections[index].children[1].classList.toggle('active');
        }
    }

    const options = [
        {
            value: "box_promo",
            text: "promo"
        },
        {
            value: "box_reduce",
            text: "reduce"
        },
        {
            value: "box_safe",
            text: "safe"
        },
        {
            value: "box_insulation",
            text: "insulation"
        },
    ]
    return (
        <div className={error ? `section-wrapper environment-box error-section environment-box-${props.index}` : `section-wrapper environment-box environment-box-${props.index}`}>
            <DragDropContext onDragEnd={handleRLDDChange}>
                <Droppable droppableId="section-items">
                    {(provided) => (
                        <div className="section-items" {...provided.droppableProps} ref={provided.innerRef}>
                        {props.data && props.data.boxes && props.data.boxes.map((item, index) => (
                            <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                {(provided) => provided && (
                                    <div className="environment-content" {...provided.draggableProps} ref={provided.innerRef}>
                                        <div className="section-index">
                                            <span onClick={() => rolledSection(index)}
                                                aria-label="Zwiń lub rozwiń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, rolledSection, index)}>Box {index + 1}</span>
                                            {!error &&
                                            <i {...provided.dragHandleProps} 
                                                className="material-icons"
                                                aria-label="Przeciągnij sekcje"
                                                tabIndex="0"
                                                role="button">open_with</i>
                                            }
                                            <i onClick={() => removeItem(index)} 
                                                aria-label="Usuń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, removeItem, index)}
                                                className="material-icons remove-item-icon">delete</i>
                                        </div>
                                        <div className="content">
                                            <div className="row-section">
                                                <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateFields(val, index, 'text') } value={item.text} label="Treść" />
                                            </div>
                                            <div className="row-section select-options">
                                                <Select name="status"
                                                    data={ options } 
                                                    value={ item.class }
                                                    label={"Class"}
                                                    handle={ (val) => updateFields(val, index, 'class') } 
                                                />
                                            </div>
                                            <div className="row-section">
                                                <MediaLoad setFunction={ val => updateFields(val, index, 'img') } value={item.img} label={"Zdjęcie"} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="add-item-in-section">
                <Button title="Dodaj kolejny box" setFunction={() => addItem()} />   
            </div>
        </div>
    );
}

export default FilesDownload;