import React, { useState, useEffect } from 'react'
import MediaLoad from './../../../media-load/MediaLoad';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from './../../../button/Button'
import SelectItems from './../../../select-items/SelectItems';
import axios from 'axios';
import ApiUrl from './../../../../services/ApiUrl';

let validateNumber = 0;
const LogosInline = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ brands, setBrands ] = useState([]);
    
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                logos: [],
            }, props.index);
            getPosts();
            setLoad(true);
        }
        if(!load) { 
            validateNumber = props.validate;
        }
    }, [load])
    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data.logos) {
                props.onChange({
                    logos: [],
                }, props.index);
            }
            getPosts();
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.logos-inline-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const updateFields = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        saveData(newData);
    }

    const saveData = (data) => {
        props.onChange({
            ...data
        }, props.index);
    }

    const getPosts = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getPages?langID=${props.langID}&postTypeSlug=brands`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setBrands(res);
        })
    }
    
    return (
        <div className={error ? `section-wrapper logos-inline error-section logos-inline-${props.index}` : `section-wrapper logos-inline logos-inline-${props.index}`}>
            <div className="section-items">
                <div className="row-section">
                    <SelectItems
                        label="Logosy"
                        data={ brands } 
                        value={ props.data.logos }
                        handle={ (val) => updateFields(val, 'logos') } 
                        forceValidate={ forceValidate } 
                        column="xs-12" />
                </div>
            </div>
            {/* <DragDropContext onDragEnd={handleRLDDChange}>
                <Droppable droppableId="section-items">
                    {(provided) => (
                        <div className="section-items" {...provided.droppableProps} ref={provided.innerRef}>
                        {props.data && props.data.logos && props.data.logos.map((item, index) => (
                            <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                {(provided) => provided && (
                                    <div className="logos-content" {...provided.draggableProps} ref={provided.innerRef}>
                                        <div className="section-index">
                                            <span onClick={() => rolledSection(index)}
                                                aria-label="Zwiń lub rozwiń wiersz"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, rolledSection, index)}>Wiersz {index + 1}</span>
                                            {!error &&
                                            <i {...provided.dragHandleProps} 
                                                className="material-icons"
                                                aria-label="Przeciągnij wiersz"
                                                tabIndex="0"
                                                role="button">open_with</i>
                                            }
                                            <i onClick={() => removeItem(index)} 
                                                aria-label="Usuń wiersz"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, removeItem, index)}
                                                className="material-icons remove-item-icon">delete</i>
                                        </div>
                                        <div className="content">
                                            <div className="row-section">
                                                <MediaLoad forceValidate={ forceValidate } setFunction={ val => updateFields(val, index, 'image') } value={item.image} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="add-item-in-section">
                <Button title="Dodaj kolejne logo" setFunction={() => addItem()} />   
            </div> */}
        </div>
    );
}

export default LogosInline;