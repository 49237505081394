import React, { useState, useEffect } from 'react'
import MediaLoad from '../../../media-load/MediaLoad';
import Input from '../../../input/Input';

let validateNumber = 0;
const HumanTouch = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                pagination: '6',
                btnText: 'Load more',
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    pagination: '6',
                    btnText: 'Load more',
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateFields = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        saveData(newData);
    }

    const saveData = (data) => {
        props.onChange({
            ...data
        }, props.index);
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.human-houch-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    return (
        <div className={error ? `section-wrapper human-houch error-section human-houch-${props.index}` : `section-wrapper human-houch human-houch-${props.index}`}>
            <div className="section-items">
                <div className="row-section">
                    <Input  required={ true }
                        setFunction={ val => updateFields(val, 'pagination') }
                        value={props.data.pagination}
                        label={ 'Paginacja' }
                        regExp={ /^[0-9]+$/ }
                        minChar={ 1 } 
                        forceValidate={ forceValidate } 
                    />
                </div>
                <div className="row-section">
                    <Input  required={ true }
                        setFunction={ val => updateFields(val, 'btnText') }
                        value={props.data.btnText}
                        label={ 'Treść przycisku doładowującego' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate } 
                    />
                </div>
            </div>
        </div>
    );
}

export default HumanTouch;