import React, { useState, useEffect, useRef } from "react"
import MagnifyIcon from './../../svg-components/MagnifyIcon';

export default props => {
    let [ value, setValue ] = useState('');
    let [ error, setError ] = useState( false );
    let [ load, setLoad ] = useState( false );
    let [ errorText, setErrorText ] = useState( false );
    let ref = useRef(null);

    useEffect(() => {
        if ( !load ) {
            setValue(props.value)
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if ( load ) {
            validate();
        }
    }, [props.forceValidate])

    useEffect(() => {
        if(load) {
            setValue(props.value)
        }
    }, [props.value])

    const validate = () => {
        let val = props.value
        // check if empty
        if(props.required) {
            if ( !val || val.length == 0) {
                setErrorText('To pole jest wymagane.')
                setError(true);
                return false;
            } else {
                setError(false);
            }
    
            //check length 
            if ( val.length < props.minChar ) {
                setError(true);       
                setErrorText('Za mało znaków.');
                return false;
            } else {
                setError(false);
            }
    
            //check format
            if ( props.regExp ) {
                let regExp = new RegExp(props.regExp);
                if(regExp.test(val)) {
                    setError(false);
                } else {
                    setError(true);       
                    setErrorText('Zły format.')
                }
            }
        }

        return true;
    }

    const clearHTML = () => {
        props.setFunction(ref.current.innerText)
    }

    return (
        <div className={"input-wrapper-component " + ( error ? 'error ' : '') + (props.class ? props.class : 'btn-primary') + (props.type ? ' input-' + props.type : '')}>
            {props.label &&
                <label for={props.name}>{props.label}</label>
            }
            <div className="input-box">
                <small className="clear" onClick={() => clearHTML()}>wyczyść HTML</small>
                {/* <div className="textarea" contentEditable={true} dangerouslySetInnerHTML={{ __html: value }} onBlur={ (e) => props.setFunction((e.target.innerHTML)) } onKeyPress={(e) => props.onKeyPress && props.onKeyPress(e)} /> */}
                <div ref={ref} className="textarea" contentEditable={true} dangerouslySetInnerHTML={{ __html: value }} onBlur={ (e) => props.setFunction((e.target.innerHTML)) } onKeyPress={(e) => props.onKeyPress && props.onKeyPress(e)} />
            </div>
            { error && 
                <span className="error-text">{errorText}</span>
            }
        </div>
    )
}
