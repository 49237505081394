import React, { useState, useEffect } from 'react';
import FocusTrap from 'focus-trap-react';
import Button from './../button/Button';

const Popup = props => {
    let [ load, setLoad ] = useState(false);

    useEffect(() => {
        if(!load) {
            changeZIndexMenu();
            setLoad(true);
        }
    }, [load])

    const onKeyPressCloseModal = (e) => {
        if(e.key === 'Enter' || e.key == 'Escape') {
            close();
        }
    }

    const changeZIndexMenu = () => {
        if(typeof window !== "undefined") {
            let menu = document.getElementById('menu');
            if(menu) {
                menu.classList.add('zIndex');
            }
        }
    }

    const close = () => {
        props.close();
        let menu = document.getElementById('menu');
        if(menu) {
            menu.classList.remove('zIndex');
        }
    }

    return (
        <FocusTrap>
            {load &&
            <div className="popup-container">
                <div className="popup" role="alert">
                    <div className="popup-wrapper">
                        <div className="popup-content">
                            <div className="editor" dangerouslySetInnerHTML={{ __html: props.editor }} tabIndex="0" />
                            {props.btnText &&
                                <Button title={ props.btnText } setFunction={ () => props.action() } />
                            }
                        </div>
                        <div className="popup-header">
                            <span tabIndex="0" role="button" onClick={() => close()} onKeyPress={(e) => onKeyPressCloseModal(e)}>Zamknij<p>X</p></span>
                        </div>
                    </div>
                </div>
            </div>
            }
        </FocusTrap>
    )
}

export default Popup;