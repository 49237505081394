import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Input from '../../../components/input/Input';
import axios from 'axios';
import ApiUrl from '../../../services/ApiUrl';
import Button from './../../../components/button/Button';
import { setNotificationAlert } from './../../actions/index';
import { connect } from 'react-redux';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            secondPassword: '',
            forceValidate: false,
            blocked: false,
        }
    }

    validate = async(e) => {
        await this.setState({ forceValidate: true });
        await this.wait(100);
        await this.setState({ forceValidate: false });
        let errors = await this.checkErrors();
        if(!errors) {
            this.resetPassword();
        }
    }

    wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.form-reset-password .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    async resetPassword() {
        if(!this.state.blocked) {
            this.setState({ blocked: true })
            let checkSamePassword = false;
            if(this.state.password === this.state.secondPassword) {
                checkSamePassword = true;
            }else{
                this.props.setNotificationAlert("<p>Wprowadzone hasła muszą być identyczne</p>")
            }

            if(checkSamePassword) {
                let search = window.location.search;
                let url = new URLSearchParams(search);
                let token = url.get('token');
                axios({
                    method: 'post',
                    url: `${ApiUrl()}/changePassword`,
                    data: {
                        password: this.state.password,
                        token: token,
                    }
                }).then(response => {
                    let res = JSON.parse(response.request.response);
                    if(res.status == 1) {
                        this.setState({
                            password: '',
                            secondPassword: ''
                        })
                        this.props.setNotificationAlert("<p>Twoje nowe hasło zostało ustawione</p>")
                        this.props.history.push("/login");
                    }else{
                        this.props.setNotificationAlert("<p>Nie udało się zmienić Twojego hasła. Spróbuj ponownie później</p>")
                    }
                }).catch(e => {
                    this.props.setNotificationAlert("<p>Nie udało się zmienić Twojego hasła. Spróbuj ponownie później</p>")
                })
            }
        }
    }
    
    render() {
        return (
            <div className="form-reset-password">
                <h1>Wprowadź nowe hasło</h1>
                <form>
                    <Input  
                        required={ true }
                        setFunction={ val => this.setState({ password: val }) }
                        value={ this.state.password }
                        placeholder={ 'Twoje nowe hasło' }
                        regExp={ /.*/ }
                        minChar={ 6 } 
                        type="password"
                        forceValidate={ this.state.forceValidate } 
                        onClickEnter={ () => this.validate() }
                    />
                    <Input  
                        required={ true }
                        setFunction={ val => this.setState({ secondPassword: val }) }
                        value={ this.state.secondPassword }
                        placeholder={ 'Twoje nowe hasło' }
                        regExp={ /.*/ }
                        minChar={ 6 } 
                        type="password"
                        forceValidate={ this.state.forceValidate } 
                        onClickEnter={ () => this.validate() }
                    />
                    <Button title="Ustaw nowe hasło" setFunction={() => this.validate()} />
                </form>
                <div className="navigation">
                    <small><Link tabIndex="0" role="button" to="/login">Wróć do logowania</Link></small>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setNotificationAlert
}

const mapStateToProps = (state) => {
    return { }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)