import React, { useState, useEffect } from 'react'
import MediaLoad from './../media-load/MediaLoad';

const ImagePost = ( props ) => {
    let [ error, setError ] = useState(false);
    return (
        <div className={error ? "image-post settings-box error" : "image-post settings-box"} data-alert={"Należy wybrać media"}>
            <div className="header">
                <span>Zdjęcie wpisu</span>
            </div>
            <div className="content">
                <MediaLoad forceValidate={ props.forceValidate } setFunction={ val => props.setFunction(val) } value={props.img} disableBtn={true} only="image" setError={(status) => setError(status)} />
            </div>
        </div>
    );
}

export default ImagePost;
 