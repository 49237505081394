import React, { useState, useEffect } from 'react'
import DateTimePicker from 'react-datetime-picker';

const DateTime = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ date, setDate ] = useState();

    useEffect(() => {
        if(!load && props.date) {
            setDate(props.date)
            setLoad(true);
        }
    }, [load, props.date])

    return (
        <div className="date settings-box">
            <div className="header">
                <span>Data utworzenia</span>
            </div>
            <div className="content">
                <DateTimePicker
                    onChange={(val) => setDate(val)}
                    onBlur={(val) => props.setFunction(date)}
                    value={date}
                    locale="pl-PL"
                    format="y-MM-dd HH:mm:ss"
                    className="date-timer"
                />
            </div>
        </div>
    );
}

export default DateTime;
