import React, { useEffect, useState } from 'react'

const InformationCard = (props) => {

    return (
        <>
            {props.text &&
                <div className="information-card">
                    {props.icon &&
                        <div className="icon">
                            {props.icon}
                        </div>
                    }
                    <div className="text" dangerouslySetInnerHTML={{ __html: props.text }} />
                </div> 
            }
        </>   
    );
}

export default InformationCard;
