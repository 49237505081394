import React, { useState, useEffect } from 'react'
import {Route, Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux'

export const PermissionsRoute = ({component: Component, ...rest}) => {
    let userPermissions = useSelector(state => state.userPermissions);
    let [ type, setType ] = useState(false);

    useEffect(() => {
        setType(rest.type)
    }, [rest])

    return (
        <>
        {type && userPermissions.length > 0 &&
            <Route 
                {...rest} 
                render= {props => {
                    if(userPermissions.includes(type) || type == "page-dashboard") {
                        return <Component {...props} />
                    }else{
                        return <Redirect to={
                            {
                                pathname: "/dashboard",
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    }
                }}
            />
        }
        </>
    )
}