import React, { useState, useEffect } from 'react'
import Input from './../../../input/Input';
import Textarea from './../../../input/Textarea';
import MediaLoad from './../../../media-load/MediaLoad';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from './../../../button/Button';

let validateNumber = 0;
const ImagesDesc = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                items: [
                    {
                        title: '',
                        text: '',
                        img: ''
                    },
                ],
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data.items) {
                props.onChange({
                    items: [
                        {
                            title: '',
                            text: '',
                            img: ''
                        },
                    ],
                }, props.index)
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(props.data.items);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        saveData(items);
    }

    const updateFields = (val, index, label) => {
        let newData = props.data.items;
        newData.map((item, i) => i == index ? item[label] = val : item);
        saveData(newData);
    }

    const addItem = () => {
        let newData = [...props.data.items, {
            title: '',
            text: '',
            img: ''
        }]
        saveData(newData);
    }

    const removeItem = (index) => {
        let newData = props.data.items.filter((item, i) => i == index ? false : item);
        saveData(newData);
    }

    const saveData = (data) => {
        props.onChange({
            ...props.data,
            items: data
        }, props.index)
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.images-desc-box-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    const rolledSection = (index) => {
        if(typeof window !== "undefined") {
            let sections = document.querySelectorAll(`.images-desc-box-${props.index} .images-desc-content`);
            sections[index].children[1].classList.toggle('active');
        }
    }

    return (
        <div className={error ? `section-wrapper images-desc-box error-section images-desc-box-${props.index}` : `section-wrapper images-desc-box images-desc-box-${props.index}`}>
            <DragDropContext onDragEnd={handleRLDDChange}>
                <Droppable droppableId="section-items">
                    {(provided) => (
                        <div className="section-items" {...provided.droppableProps} ref={provided.innerRef}>
                        {props.data && props.data.items && props.data.items.map((item, index) => (
                            <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                {(provided) => provided && (
                                    <div className="images-desc-content" {...provided.draggableProps} ref={provided.innerRef}>
                                        <div className="section-index">
                                            <span onClick={() => rolledSection(index)}
                                                aria-label="Zwiń lub rozwiń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, rolledSection, index)}>Zdjęcie {index + 1}</span>
                                            {!error &&
                                            <i {...provided.dragHandleProps} 
                                                className="material-icons"
                                                aria-label="Przeciągnij sekcje"
                                                tabIndex="0"
                                                role="button">open_with</i>
                                            }
                                            <i onClick={() => removeItem(index)} 
                                                aria-label="Usuń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, removeItem, index)}
                                                className="material-icons remove-item-icon">delete</i>
                                        </div>
                                        <div className="content">
                                            <div className="row-section">
                                                <Input  required={ true }
                                                    setFunction={ val => updateFields(val, index, 'title') }
                                                    value={ item.title }
                                                    label={ 'Tytuł' }
                                                    regExp={ /.*/ }
                                                    minChar={ 3 } 
                                                    forceValidate={ forceValidate } 
                                                />
                                            </div>
                                            <div className="row-section">
                                                <Textarea  name="text"
                                                    label="Treść"
                                                    value={ item.text }
                                                    setFunction={ val => updateFields(val, index, 'text') }
                                                    forceValidate={ forceValidate } 
                                                    minChar={ 3 }
                                                    class="btn-primary btn-simple" />
                                            </div>
                                            <div className="row-section">
                                                <MediaLoad forceValidate={ forceValidate } setFunction={ val => updateFields(val, index, 'img') } value={item.img} label={"Zdjęcie"} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="add-item-in-section">
                <Button title="Dodaj koleje zdjęcie" setFunction={() => addItem()} />   
            </div>
        </div>
    );
}

export default ImagesDesc;