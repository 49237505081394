import React, { useState, useEffect } from 'react'
import MediaLoad from '../../../media-load/MediaLoad';
import Editor from '../../../editor/Editor';
import Link from './../../../link/Link';

let validateNumber = 0;
const Banner = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                text: '',
                image: '',
                link: {
                    url: '',
                    target: "false",
                    text: '',
                    uppercase: 'initial'
                }
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    text: '',
                    image: '',
                    link: {
                        url: '',
                        target: "false",
                        text: '',
                        uppercase: 'initial'
                    }
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateFields = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        saveData(newData);
    }

    const saveData = (data) => {
        props.onChange({
            ...data
        }, props.index);
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.slider-box-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    return (
        <div className={error ? `section-wrapper slider-box error-section slider-box-${props.index}` : `section-wrapper slider-box slider-box-${props.index}`}>
            <div className="section-items">
                <div className="row-section">
                    <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateFields(val, 'text') } value={props.data.text} label="Treść" />
                </div>
                <div className="row-section">
                    <MediaLoad forceValidate={ forceValidate } setFunction={ val => updateFields(val, 'image') } value={props.data.image} label="Tło" />
                </div>
                {props.data.link &&
                    <div className="row-section">
                        <Link value={props.data.link} setFunction={ val => updateFields(val, 'link')} label="Link" />
                    </div>
                }
            </div>
        </div>
    );
}

export default Banner;