import React, { useState, useEffect } from 'react'
import Editor from '../../../editor/Editor';
import Select from './../../../select/Select';

let validateNumber = 0;
const Header = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                header: '',
                grayTextColor: "false",
                hiddenBottomMargin: "false",
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    header: '',
                    grayTextColor: "false",
                    hiddenBottomMargin: "false",
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateFields = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        saveData(newData);
    }

    const saveData = (data) => {
        props.onChange({
            ...data
        }, props.index);
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.header-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const optionsGray = [
        {
            value: "false",
            text: "normalny kolor tekstu"
        },
        {
            value: "true",
            text: "szary kolor tekstu"
        },
    ]

    const optionsNoMargin = [
        {
            value: "false",
            text: "nie usuwaj marginesu"
        },
        {
            value: "true",
            text: "usuń margines"
        },
    ]

    return (
        <div className={error ? `section-wrapper header error-section header-${props.index}` : `section-wrapper header header-${props.index}`}>
            <div className="section-items">
                <div className="row-section">
                    <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateFields(val, 'header') } value={props.data.header} label="Treść" />
                </div>
                <div className="row-section">
                    <Select name="gray"
                        data={ optionsGray } 
                        label="Szary kolor tekstu"
                        value={ String(props.data.grayTextColor) }
                        handle={ (val) => updateFields(val, 'grayTextColor') } 
                        class="btn-primary btn-simple" />
                </div>
                <div className="row-section">
                    <Select name="margin"
                        data={ optionsNoMargin } 
                        label="Margines dolni"
                        value={ String(props.data.hiddenBottomMargin) }
                        handle={ (val) => updateFields(val, 'hiddenBottomMargin') } 
                        class="btn-primary btn-simple" />
                </div>
            </div>
        </div>
    );
}

export default Header;