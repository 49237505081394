import React, { useState, useEffect, useRef} from "react"
import { SketchPicker } from 'react-color';

export default props => {
    let [error, setError] = useState(false);
    let [load, setLoad] = useState(false);
    let [errorText, setErrorText] = useState(false);

    useEffect(() => {
        if (!load) {
            // checkDialog();
            if(props.colors && !props.value) {
                props.setFunction(props.colors[0]);
            }
            setLoad(true);
        }
    }, [load])

    return (
        <div className={error ? "color-picker error" : "color-picker"} data-alert={errorText}>
            {props.label &&
                <label>{props.label}</label>
            }
            {!props.colors &&
                <SketchPicker
                    color={ props.value }
                    onChangeComplete={ (val) => props.setFunction(val.hex) }
                />
            }
            {props.value && props.colors && props.colors.map((color, index) => (
                <div className={"color" + (props.value == color ? " active" : "")} style={{ background: color }} key={index} onClick={() => props.setFunction(color)} />
            ))}
            {error && errorText &&
                <span className="error-text">{errorText}</span>
            }
        </div>
    )
}