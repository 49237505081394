import React, { useState, useEffect } from 'react'
import Editor from '../../../editor/Editor';
import Textarea from './../../../input/Textarea';

let validateNumber = 0;
const BannerTitle = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                text: '',
                textUnderIcon: '',
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    text: '',
                    textUnderIcon: '',
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateFields = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        saveData(newData);
    }

    const saveData = (data) => {
        props.onChange({
            ...data
        }, props.index);
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.thx-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    return (
        <div className={error ? `section-wrapper thx error-section thx-${props.index}` : `section-wrapper thx thx-${props.index}`}>
            <div className="section-items">
                <div className="row-section">
                    <Textarea  required={ true }
                        setFunction={ val => updateFields(val, 'text') }
                        value={props.data.text}
                        label={ 'Treść' }
                    />
                    {/* <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateFields(val, 'text') } value={props.data.text} label={"Treść"} /> */}
                </div>
                <div className="row-section">
                    <Textarea  required={ true }
                        setFunction={ val => updateFields(val, 'textUnderIcon') }
                        value={props.data.textUnderIcon}
                        label={ 'Treść pod ikoną' }
                    />
                    {/* <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateFields(val, 'textUnderIcon') } value={props.data.textUnderIcon} label={"Treść pod ikoną "} /> */}
                </div>
            </div>
        </div>
    );
}

export default BannerTitle;