const GetFormatDateTime = (date) => {
    if(date) {
      let hours = date.getHours();
      hours = hours < 10 ? `0${hours}` : hours;
      let minutes = date.getMinutes();
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      let month = date.getMonth()+1;
      let seconds = "00";
      month = month < 10 ? `0${month}` : month;
      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      
      return date.getFullYear() + "-" + month + "-" + day + " " + hours + ':' + minutes + ":" + seconds;
    }else{
      return '';
    }
  }
  
  export default GetFormatDateTime;