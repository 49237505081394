import React, { useState } from 'react';

const PageTitle = ( { title, subTitle } ) => {
    return (
        <div className="page-title-container">
            {title &&
                <h1>{title}</h1>
            }
            {subTitle &&
                <p>{subTitle}</p>
            }
            <hr />
        </div>
    );
}

export default PageTitle;
