import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const GenerateAnswerEXCEL = (props) => {
    let [ load, setLoad ] = useState(false);
    let [ data, setData ] = useState([]);

    useEffect(() => {
        if(!load) {
            generateData();
            setLoad(true);
        }
    }, [load])

    const generateData = () => {
        let newData = [];
        props.data && props.data.questions.map(question => {
            let answer = "";
            let type = "";
            question.questionsAnswers.map((answerItem, index) => {
                if(index == 0) {
                    if(question.type == "semi-open") {
                        if(answerItem.value) {
                            answer = answerItem.value;
                        }else{
                            answer = answerItem.checked;
                        }
                    }else{
                        answer = answerItem.value;
                    }
                }else{
                    if(question.type == "semi-open") {
                        if(answerItem.value) {
                            answer = `${answer}, ${answerItem.value}`;
                        }else{
                            answer = `${answer}, ${answerItem.checked}`;
                        }
                    }else{
                        answer = `${answer}, ${answerItem.value}`;
                    }
                }
            })
            if(question.type == "closed") {
                type = "Pytanie zamknięte";
            }else if(question.type == "scale") {
                type = "Pytanie skala"
            }else if(question.type == "semi-open") {
                type = "Pytanie półotwarte"
            }else if(question.type == "multiple-choice") {
                type = "Pytanie wielokrotny wybór"
            }else if(question.type == "open") {
                type = "Pytanie otwarte"
            }

            newData = [...newData, {
                type: type,
                question: question.title,
                answer: answer,
                email: props.data.email ? props.data.email : "nie podano",
            }]
        })
        setData(newData);
    }

    const onKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.target.click()
        }
    }

  return (
    <>
        <ExcelFile element={<div className="download" aria-label="Pobierz excel" onKeyDown={(e) => onKeyDown(e)} role="button" tabIndex="0">XLSX <i className="material-icons">download</i></div>}>
            <ExcelSheet data={data} name="Odpowiedzi autotestu">
                <ExcelColumn label="Typ pytania" value="type" />
                <ExcelColumn label="Pytanie" value="question" />
                <ExcelColumn label="Odpowiedź" value="answer" />
                <ExcelColumn label="E-mail" value="email" />
            </ExcelSheet>

        </ExcelFile>
      {/* <div className="action enter" onClick={ () => download() }>Pobierz XLSX <i className="material-icons">download</i></div> */}
    </>
  );
};

export default GenerateAnswerEXCEL;