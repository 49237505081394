import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ApiUrl from './../../services/ApiUrl';
import dayjs from 'dayjs';

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionsOpenAndSemiOpen: [],
      questionsOther: [],
      clinics: [],
      posts: [],
      createDate: '',
      header: '',
      summaryAllPointsText: '',
      summaryGroupPointsText: [],
      language: null,
      translates: false,
      points: 0,
    }
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = () => {
    let newOpenQuestions = [];
    let newOtherQuestions = [];
    let newPoints = 0;
    this.props.data.questions && this.props.data.questions.map((question, index) => {
      if(question.type == "semi-open" || question.type == "open") {
        newOpenQuestions = [...newOpenQuestions, question];
      }else{
        newPoints += question.questionsAnswers[0].points;
        newOtherQuestions = [...newOtherQuestions, question];
      }
    })

    this.setState({
      questionsOpenAndSemiOpen: newOpenQuestions,
      questionsOther: newOtherQuestions,
      clinics: this.props.data.clinics,
      createDate: this.props.data.created_at,
      header: this.props.data.header,
      posts: this.props.data.posts,
      summaryAllPointsText: this.props.data.summary_all_points_text,
      summaryGroupPointsText: this.props.data.summary_group_points_text,
      language: this.props.data.language,
      translates: this.props.data.pdf_translates,
      points: newPoints,
    })
  }

  getTranslate = (metaKey) => {
    let result = 'Nie znaleziono tłumaczenia';
    if(this.state.translates) {
      result = this.state.translates.filter(item => item.meta_key == metaKey ? item : false)
      if(result && result.length > 0) {
        result = result[0].value;
      }
    }
    return result;
  }

  getTranslateByPoints = () => {
    if(this.state.points == 1) {
      return 'pdf_points_1';
    }else if(this.state.points == 2 || this.state.points == 3 || this.state.points == 4) {
      return 'pdf_points_2';
    }else{
      return 'pdf_points_3';
    }
  }

  render() {
    const find = ["&nbsp;", "&oacute;", "&Oacute;"];
    const replace = [" ", "ó", "Ó"]
    String.prototype.replaceArray = function (find, replace) {
        let replaceString = this;
        for (let i = 0; i < find.length; i++) {
            // global replacement
            let pos = replaceString.indexOf(find[i]);
            while (pos > -1) {
                replaceString = replaceString.replace(find[i], replace[i]);
                pos = replaceString.indexOf(find[i]);
            }
        }
        return replaceString;
    };
    return (
        <div className={"container-answers-pdf"}>
            <div className="header">
              <a className="logo" href="https://dorada.uj.edu.pl" alt="Dorada - Przełamuj bariery">
                <img src={this.props.logoUrl} alt="Dorada - Przełamuj bariery" />
              </a>
              <div className="text">
                  <span>{this.getTranslate('pdf_header')}</span>
              </div>
            </div>
            <div className="wrapper">
              <div className="info">
                {this.state.language &&
                  <span>{this.getTranslate('pdf_answers_sent')} {dayjs( this.state.createDate, true).locale((this.state.language.shortcode).toLowerCase()).format('DD MMMM YYYYr., HH:mm') }</span>
                }
              </div>
              <div className="title">
                <span>{this.state.header.replaceArray(find, replace)}</span>
              </div>
              <div className="subtitle">
                <span>{this.getTranslate('pdf_part_one')}</span>
              </div>
              <div className="test">
                  {this.state.questionsOther && this.state.questionsOther.length > 0 && this.state.questionsOther.map((question, index) => (
                    <>
                        {(question.type == "closed" || question.type == "scale") &&
                          <div className="question">
                            <div className="row-pdf">
                              <span>{index +1}. {question.title}</span>
                              <span className="short-text">{question.questionsAnswers[0].value} ({question.questionsAnswers[0].points}p.)</span>
                            </div>
                          </div>
                        }
                        {question.type == "multiple-choice" &&
                          <div className="question">
                            <div className="row-pdf">
                              <span>{index +1}. {question.title}</span>
                            </div>
                            {question.questionsAnswers && question.questionsAnswers.map((answer, i) => (
                                <div className="row-pdf" key={i}>
                                  <span className="item-of-multiple">- {answer.value}</span>
                                  <span className="item-of-multiple" className="short-text">{this.getTranslate('pdf_yes')} ({answer.points}p.)</span>
                                </div>
                            ))}
                          </div>
                        }
                    </>
                  ))}
              </div>
              <div className="space" />
              <div className="subtitle">
                <span>{this.getTranslate('pdf_part_two')}</span>
              </div>
              <div className="test">
                  {this.state.questionsOpenAndSemiOpen && this.state.questionsOpenAndSemiOpen.length > 0 && this.state.questionsOpenAndSemiOpen.map((question, index) => (
                    <>
                    {question.questionsAnswers && question.questionsAnswers[0] &&
                      <>
                        {question.type == "semi-open" &&
                          <div className="question">
                            <div className="row-pdf">
                              <span>{index +1}. {question.title}</span>
                              <span className="short-text">{question.questionsAnswers[0].checked} ({question.questionsAnswers[0].points}p.)</span>
                            </div>
                            {question.questionsAnswers[0].value &&
                              <span className="blue-open">{question.questionsAnswers[0].value}</span>
                            }
                          </div>
                        }
                        {question.type == "open" &&
                          <div className="question">
                            <div className="row-pdf">
                              <span>{index +1}. {question.title}</span>
                              <span className="short-text">({question.questionsAnswers[0].points}p.)</span>
                            </div>
                            {question.questionsAnswers[0].value &&
                              <span className="blue-open">{question.questionsAnswers[0].value}</span>
                            }
                          </div>
                        }
                      </>
                    }
                    </>
                  ))}
              </div>
              <div className="subtitle">
                <span>{this.getTranslate('pdf_your_result')}</span><p>({this.getTranslate('pdf_for_questions')}): </p><span>{`${this.state.points} ${this.getTranslate(this.getTranslateByPoints())}`}</span>
              </div>
              <div className="text">
                  {this.state.summaryGroupPointsText && this.state.summaryGroupPointsText.map((text, index) => (
                    <>
                      <span key={index} dangerouslySetInnerHTML={{ __html: text }} /><br />
                      {index == (this.state.summaryGroupPointsText.length -1) &&
                        <br/>
                      }
                    </>
                  ))}
                  {this.state.summaryAllPointsText &&
                    <>
                      <span>{this.state.summaryAllPointsText}</span>
                      <br />
                    </>
                  }
                  {this.state.posts && (this.state.posts).length > 0 &&
                    <>
                      <span className="blue-open">{this.getTranslate('pdf_read_more_articles')}:</span>
                      {this.state.posts && this.state.posts.map((post, index) => (
                        <a href={post.url} key={index} alt={post.title}>
                        {/* <a href={post.url} key={index} alt={"alt " + index} title={"title " + index}> */}
                          <span className="blue-open">- {post.title}</span>
                        </a>
                      ))}
                    </>
                  }
              </div>
              <div className="subtitle">
                <span>{this.getTranslate('pdf_present_clinics')}:</span>
              </div>
              <div className="clinics">
                {this.state.clinics && this.state.clinics.map((clinic, index) => (
                  <div className="clinic" key={index}>
                    <div className="title-wrapper">
                      <span>{clinic.title}</span>
                    </div>
                    <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: clinic.editor }} />
                  </div>
                ))}
              </div>
            </div>
        </div>
    );
  }
}

const GenerateAnswerPDF = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Dorada - autotest",
    bodyClass: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? "firefox" : "other",
    pageStyle: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? `@page { margin: 0 }` : `@page { margin: 30px 0 }`,
  });
  const onKeyDown = (e, functionName, functionValue = false, functionValueSecond = false) => {
    if (e.key === 'Enter') {
        functionName(functionValue, functionValueSecond)
    }
  }
  let logo = ApiUrl().substr(0, ApiUrl().lastIndexOf("api")) + 'logo/dorada-logo.png';
  let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  
  return (
    <>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} data={props.data} logoUrl={logo} isFirefox={isFirefox} />
      </div>
      <div className="action download" 
        onClick={ () => handlePrint() }
        role="button"
        tabIndex="0"
        onKeyDown={(e) => onKeyDown(e, handlePrint)}
        aria-label="Pobierz plik PDF">PDF <i className="material-icons">download</i>
      </div>
    </>
  );
};

export default GenerateAnswerPDF;