const WebUrl = () => {

    let link = '';
    if ( window.location.hostname === 'localhost') {
        link = 'http://localhost:3001'
    }else{
        link = 'https://vox-showroom.norddigital.pl'
    }

    return link;
}

export default WebUrl;