import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ApiUrl from '../../../../services/ApiUrl';
import axios from 'axios';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/select/Select';
import Button from '../../../../components/button/Button';
import PageTitle from './../../../../components/page-title/PageTitle';
import readTextNvda from './../../../../services/readTextNvda';

const NewUser = ({ match }) => {
	let [ load, setLoad ] = useState(0);
	let [ login, setLogin ] = useState();
	let [ email, setEmail ] = useState();
	let [ name, setName ] = useState();
	let [ surname, setSurname ] = useState();
	let [ password, setPassword ] = useState();
	let [ accountTypeSelect, setAccountTypeSelect ] = useState();
	let [ forceValidate, setForceValidate ] = useState(false);
	let [ errorLogin, setErrorLogin ] = useState(false);
	let [ errorEmail, setErrorEmail ] = useState(false);
	let [ errorPassword, setErrorPassword ] = useState(false);
	let [ optionsAccountTypes, setOptionsAccountTypes ] = useState([]);
	let [ accountTypes, setAccountTypes ] = useState([]);
	let users = useSelector((state) => state.users);
	let [ blockedBtn, setBlockedBtn ] = useState(false);
	let [ infoPermission, setInfoPermission ] = useState('');
	let countData = useSelector((state) => state.countData);
	const dispatch = useDispatch();

	useEffect(
		() => {
			if (accountTypes && accountTypes.length > 0 && optionsAccountTypes.length == 0) {
				createOptionsAccountTypes();
			}
		},
		[ accountTypes ]
	);

	useEffect(
		() => {
			if (load == 0) {
				generatePassword();
				getAccountTypes();
				setLoad(1);
			}
		},
		[ load ]
	);

	const getAccountTypes = () => {
		axios({
			method: 'get',
			url: `${ApiUrl()}/getAccountTypes`,
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		}).then((response) => {
			let res = JSON.parse(response.request.response);
			setAccountTypes(res.data);
		});
	};

	const createOptionsAccountTypes = () => {
		let newOptions = [];
		accountTypes.map((type, i) => {
			let info = `<h2>${type.name}</h2><h3>Lista Uprawnień:</h3><ul>`;
			type.permissions &&
				type.permissions.map((perm) => {
					info = `${info}<li>${perm.name}</li>`;
				});
			newOptions = [
				...newOptions,
				{
					value: type.id,
					text: type.name,
					info: `${info}</ul>`
				}
			];
			if (i == 0) {
				setAccountTypeSelect(type.id);
				setInfoPermission(info);
			}
		});
		setOptionsAccountTypes(newOptions);
	};

	const generatePassword = () => {
		var length = 16,
			charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
			retVal = '';
		for (var i = 0, n = charset.length; i < length; ++i) {
			retVal += charset.charAt(Math.floor(Math.random() * n));
		}
		setPassword(retVal);
	};

	const clearErrors = () => {
		setErrorEmail(false);
		setErrorLogin(false);
		setErrorPassword(false);
	};

	const validate = async () => {
		await setForceValidate(true);
		await wait(100);
		await setForceValidate(false);
		let errors = await checkErrors();
		if (!errors) {
			await createUser();
		} else {
		}
	};

	let checkErrors = () => {
		let result;
		let elements = document.querySelectorAll(`.new-user-container .error`);
		if (elements.length > 0) {
			readTextNvda("new-user");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
            });
            result = true;
		} else {
			result = false;
		}
		return result;
	};

	async function wait(ms) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	}

	const createUser = async () => {
		if (!blockedBtn) {
			setBlockedBtn(true);
			axios({
				method: 'post',
				url: `${ApiUrl()}/newAdmin`,
				data: {
					userID: localStorage.getItem('userId'),
					token: localStorage.getItem('token'),
					login: login,
					email: email,
					name: name,
					surname: surname,
					password: password,
					accountType: accountTypeSelect,
					linkPage: window.location.origin,
					subject: 'Nowe konto użytkownika',
					from: 'biuro@norddigital.com',
					edit: false
				}
			})
				.then((response) => {
					let res = JSON.parse(response.request.response);
					if (res.status == 'success') {
						dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Dodano nowego użytkownika</p>` }) 
						generatePassword();
						setAccountTypeSelect();
						setLogin('');
						setEmail('');
						setName('');
						setSurname('');
						setBlockedBtn(false);
						//increment count
						incrementCountData();
					} else if( res.status == 'error_login' ) {
						dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Wybrany login został już zarejestrowany, więc nie udało się utworzyć nowego użytkownika</p>` })
						setBlockedBtn(false);
					} else if( res.status == 'error_email' ) {
						dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Wybrany email został już zarejestrowany, więc nie udało się utworzyć nowego użytkownika</p>` })
						setBlockedBtn(false);
					} else {
						dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` })
						setBlockedBtn(false);
					}
				})
				.catch((e) => {
					dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` })
					setBlockedBtn(false);
				});
		}
	};

	const incrementCountData = () => {
		let count = countData.users;
		let newCountData = countData;
		newCountData.users = ++count;
		dispatch({ type: 'COUNT_DATA', value: newCountData });
	};

	const changeAccountType = (val) => {
		let newVal = val - 1;
		setAccountTypeSelect(newVal);
		let info = `<h2>${accountTypes[newVal].name}</h2><h3>Lista Uprawnień:</h3><ul>`;
		accountTypes[newVal].permissions &&
			accountTypes[newVal].permissions.map((perm) => {
				info = `${info}<li>${perm.name}</li>`;
			});
		setInfoPermission(info);
	};

	return (
		<div className="container new-user-container">
			<div className="wrapper">
				{/* <div className="page-title">
					<h2 className="reset-top-margin">Dodaj nowego użytkownika</h2>
					<hr />
				</div> */}
				<PageTitle title="Dodaj nowego użytkownika:" subTitle="Uzupełnij dane" />
				<div className="col-wrapper">
					<div className="col">
						<Input
							name="login"
							label="Login"
							type="text"
							minChar={4}
							value={login}
							required
							setFunction={(val) => setLogin(val)}
							forceValidate={forceValidate}
							class="btn-primary btn-simple"
						/>

						<Input
							name="email"
							label="Adres e-mail"
							type="email"
							value={email}
							regExp={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
							minChar={3}
							setFunction={(val) => setEmail(val)}
							required
							forceValidate={forceValidate}
							class="btn-primary btn-simple"
						/>

						<Input
							name="name"
							label="Imię"
							minChar={2}
							type="text"
							value={name}
							setFunction={(val) => setName(val)}
							required
							forceValidate={forceValidate}
							class="btn-primary btn-simple"
						/>

						<Input
							name="surname"
							label="Nazwisko"
							type="text"
							value={surname}
							minChar={2}
							setFunction={(val) => setSurname(val)}
							required
							forceValidate={forceValidate}
							class="btn-primary btn-simple"
						/>

						<Input
							name="password"
							label="Hasło"
							type="password"
							mode="password"
							value={password}
							minChar={6}
							setFunction={(val) => setPassword(val)}
							required
							forceValidate={forceValidate}
							class="btn-primary btn-simple"
						/>

						<Select
							name="account_type"
							label="Typ konta"
							data={optionsAccountTypes}
							value={accountTypeSelect}
							infoReturn={true}
							class="btn-primary btn-simple"
							handle={(val) => changeAccountType(val)}
						/>

						<div className="btn-wrapper">
							<Button class="btn-primary" setFunction={() => validate()} title="Utwórz" />
						</div>
					</div>

					<div className="col">
						<div className="info-permission" dangerouslySetInnerHTML={{ __html: infoPermission }} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewUser;
