import React, { useState } from 'react'
import ResizeIcon from './../../svg-components/ResizeIcon';

const HeaderSort = (props) => {
    let [ ascOrDesc, setAscOrDesc ] = useState("desc");
    
    const sort = () => {
        let status = ascOrDesc == "desc" ? "asc" : "desc";
        props.setFunction(status)
        setAscOrDesc(status); 
    }

    // thisIndex

    return (
        <>
            <p onClick={ () => sort() }>{props.text}</p>
            {/* <ResizeIcon /> */}
        </>                    
    )
}   

export default HeaderSort;