import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import './styles.scss';

const WindowChat = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ visible, setVisible ] = useState(true);
    let [ chatData, setChatData ] = useState(null);
    let [ message, setMessage ] = useState('');
    let [ disabledChat, setDisabledChat ] = useState(false);
    let user = useSelector(state => state.userData );
    let messageInput = useRef();

    useEffect(() => {
        if(!load) {
            setChatData(props.data);
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load) {
            setLoad(false);
        }
    }, [props.data])

    const onKeyDown = (e, functionName, functionValue = false, functionValueSecond = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue, functionValueSecond)
        }
    }
    
    return (
        <>
        <div className={ "live-chat-wrap" + ( !visible ? ' close' : '' ) + ( chatData && chatData.status_chat === 'closed' ? ' ended' : '') + ( chatData && chatData.moderator && chatData.moderator.id !== user.id ? ' not-my' : '')} data-id={ chatData && chatData.id } tabIndex={ 1 }>
            <div className="panel-heading" >
                <div className="chat-bubble">
                    <i className="material-icons icon-chat" aria-hidden="true">chat_bubble</i>
                    { chatData && chatData.user_name }
                </div>
                <div className="left-col">
                    <div className="hide-chat">
                        <i  className="material-icons" 
                            title={ visible ? 'Ukryj czat' : 'Pokaż czat'} 
                            role="button"
                            aria-label={ `${ visible ? 'Ukryj czat' : 'Pokaż czat' } czat z ${ chatData && chatData.user_name }` }
                            tabIndex='1'
                            onKeyDown={(e) => onKeyDown(e, setVisible, !visible)}
                            onClick={ () => setVisible( !visible )}>remove</i>
                    </div>

                    <div className="close-chat" onClick={ () => props.closeChat() } onKeyDown={(e) => onKeyDown(e, props.closeChat)}>
                        <i  className="material-icons" 
                            title="Zamknij okno"
                            role="button"
                            aria-label={ `Zamknij czat z ${ chatData && chatData.user_name }` }
                            tabIndex='1' >
                            close
                        </i>
                    </div>
                </div>
            </div>
            <div className="chat-body">
                { chatData && chatData.chat_messages.map( el => (
                    <div className={"message-wrap" + ( el.moderator && el.moderator.id ? ' me': '' )} >
                        <div className="chat-date">
                            <a className="user">{ el.moderator && el.moderator.id === user.id ? user.login  : el.user_name } </a>, { dayjs( el.created_at, true).locale('pl').format('DD MMMM HH:mm:ss') }
                        </div>
                        <div className="chat-user-message">
                            <div className="chat-avatar">
                                <img src="/" alt="" />
                            </div>
                            <div class="chat-message">
                                { el.message }
                            </div>
                        </div>
                    </div>
                ))}
                { disabledChat &&
                    <div className="message-wrap" >
                        <div className="chat-date">
                            <a className="user">Powiadomienie</a>,
                        </div>
                        <div className="chat-user-message">
                            <div className="chat-avatar">
                                <img src="/" alt="" />
                            </div>
                            <div class="chat-message">
                                Użytkownik zakończył czat
                            </div>
                        </div>
                    </div>
                }
                { chatData && chatData.status_chat === 'closed' &&
                    <div className="message-wrap" >
                        <div className="chat-date">
                            <a className="user">Powiadomienie</a>,
                        </div>
                        <div className="chat-user-message">
                            <div className="chat-avatar">
                                <img src="/" alt="" />
                            </div>
                            <div class="chat-message">
                                Czat zakończony
                            </div>
                        </div>
                    </div>
                }
            </div>
            { ( chatData && chatData.status_chat !== 'closed') && ( chatData.moderator && chatData.moderator.id === user.id )  &&
                <div class="send-message">
                    <form action="sendmessage" method="post">
                        <textarea   ref={ messageInput } 
                                    name="message" 
                                    onKeyDown={ (e) => null } 
                                    rows="" 
                                    cols="" 
                                    className={"form-control user-message" + ( (chatData && chatData.status_chat === 'closed') || disabledChat ? ' disabled' : '' )} 
                                    disabled={ (chatData && chatData.status_chat === 'closed') || disabledChat ? 'true' : '' } 
                                    value={ message } 
                                    onChange={ (e) => null } 
                                    id="message" 
                                    placeholder="Wpisz wiadomość"
                                    autoFocus 
                                    tabIndex='1' >
                        </textarea>
                    </form>
                </div>
            }
        </div> 
        </>
    );
}

export default WindowChat;
