import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import ApiUrl from '../../../../services/ApiUrl';
import Editor from '../../../editor/Editor';
import Link from '../../../link/Link';
import MediaLoad from '../../../media-load/MediaLoad';
import SelectItems from '../../../select-items/SelectItems';
import Select from './../../../select/Select';

let validateNumber = 0;
const BoxTextLinks = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ pages, setPages ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let lang = useSelector(state => state.lang);

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                logo: '',
                text: '',
                link: {
                    url: '',
                    target: "false",
                    text: '',
                    uppercase: 'initial'
                },
                showBoxes: "false",
                boxesIsSmall: "false",
                linksPages: [],
                linkMore: {
                    url: '',
                    target: "false",
                    text: '',
                    uppercase: 'initial'
                }
            }, props.index);
            getPages();
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    logo: '',
                    text: '',
                    link: {
                        url: '',
                        target: "false",
                        text: '',
                        uppercase: 'initial'
                    },
                    showBoxes: "false",
                    boxesIsSmall: "false",
                    linksPages: [],
                    linkMore: {
                        url: '',
                        target: "false",
                        text: '',
                        uppercase: 'initial'
                    }
                }, props.index);
            }
            getPages();
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.box-text-links-slider-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const getPages = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/pagesList?langID=${lang.id}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setPages(res);
        })
    }

    const boxesOptions = [
        {
            value: "false",
            text: "Duży box"
        },
        {
            value: "true",
            text: "Mały box",
        }
    ]

    const showBoxesOptions = [
        {
            value: "false",
            text: "Nie pokazuj"
        },
        {
            value: "true",
            text: "Pokaż",
        }
    ]

    return (
        <div className={error ? `section-wrapper box-text-links-slider error-section box-text-links-slider-${props.index}` : `section-wrapper box-text-links-slider box-text-links-slider-${props.index}`}>
                <div className="row-section">
                    <MediaLoad forceValidate={ forceValidate } setFunction={ val => updateValue(val, 'logo') } value={props.data.logo} label="Logo" />
                </div>
                <div className="row-section">
                    <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateValue(val, 'text') } value={props.data.text} label="Treść" />
                </div>
                {props.data.link &&
                    <div className="row-section"> 
                        <Link value={props.data.link} setFunction={ val => updateValue(val, 'link')} label="Link" />
                    </div>
                }
                <div className="select-options row-section">
                    <Select name="boxes"
                        data={ showBoxesOptions } 
                        value={ props.data.showBoxes }
                        label={"Wyświetlanie boxów"}
                        handle={ (val) => updateValue(val, 'showBoxes') } 
                    />
                </div>
                {props.data.showBoxes === "true" &&
                    <>
                        <div className="select-options row-section">
                            <Select name="boxes"
                                data={ boxesOptions } 
                                value={ props.data.boxesIsSmall }
                                label={"Wielkość boxa"}
                                handle={ (val) => updateValue(val, 'boxesIsSmall') } 
                            />
                        </div>
                        {pages &&
                            <div className="row-section">
                                <SelectItems
                                    label="Boxy linkujące"
                                    data={ pages } 
                                    value={ props.data.linksPages }
                                    handle={ (val) => updateValue(val, 'linksPages') } 
                                    forceValidate={ forceValidate } 
                                    filter={true}
                                    column="xs-12" />
                            </div>
                        }
                        {props.data.linkMore &&
                            <div className="row-section"> 
                                <Link value={props.data.linkMore} setFunction={ val => updateValue(val, 'linkMore')} label="Link zobacz więcej" />
                            </div>
                        }
                    </>
                }
        </div>
    );
}

export default BoxTextLinks;