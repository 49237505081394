import React, { useState, useEffect } from "react"
import CheckIcon from './../../svg-components/CheckIcon';

export default props => {
  let [ checked, setChecked ] = useState( false );
  let [ error, setError ] = useState( false );
  let [ load, setLoad ] = useState( false );

    useEffect(() => {
        if ( load ) {
            setChecked(false);
        }
    }, [props.reset])

    useEffect(() => {
        if ( !load ) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.data) {
            setChecked(props.data.value)
        }
    }, [props.data])

    useEffect(() => {
        if ( load ) {
            validate();
        }
    }, [props.forceValidate])

    const validate = () => {
        let val = checked;

        if ( props.required && !val) {
            setError(true);
            return false;

        } else {
            setError(false);
        }

        return true;    
    }

    const toggleChecked = () => {
        setChecked( !checked ); 
        props.setFunction( !checked )
    }

    let keyPressed = (e) => {
        if ( e.code === "Enter" ) {
            toggleChecked()
        }
    }

    return (
        <div className={"checkbox-wrapper " + ( error ? 'error ' : '') + ( props.class ? props.class : '' )}>
            <div className="accept" tabIndex="0" role="checkbox" onKeyPress={ (e) => keyPressed(e)}  onClick={ () => toggleChecked() } >
                <div className={error ? "check error" : "check"}>
                    { checked &&
                        <CheckIcon />
                    }
                </div>
                <p>{props.text}</p>
            </div>
        </div>
    )
}
