import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ProtectedRoute } from './components/custom-routes/protected.route';
import LoginView from './pages/login/index';
import Dashboard from './pages/dashboard/index';
import NotificationAlert from './components/layout/notification/NotificationAlert';

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if(window.location.pathname == '/') {
      window.location.replace('/login')
    }
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Route path="/login" component={ LoginView } props={this.props} />
          <ProtectedRoute path="/dashboard" component={ Dashboard } props={this.props} />
        </Router>
        <NotificationAlert />
      </div>
    );
  }
}


