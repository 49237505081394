import React, { useState, useEffect } from 'react'
import TriangleIcon from './../../svg-components/Arrow';

const MenuItem = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ active, setActive ] = useState(false);
    let [ target, setTarget ] = useState(false);
    let [ title, setTitle ] = useState('');
    let [ url, setUrl ] = useState('');
    let item = props.item;

    useEffect(() => {
        if(!load) {
            setTarget(item.target == "false" || item.target == false ? false : true)
            setTitle(item.title);
            setUrl(item.url);
            setLoad(true);
        }
    }, [load])

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    return (
        <div key={item.id} className={active ? "page-item active" : "page-item"} aria-hidden={true} >
            <div className="header-item" 
                onClick={() => setActive(!active)}
                // role="button" 
                role="menu"
                tabIndex="0" 
                aria-label={active ? `Zwiń ${title}` : `Rozwiń ${title}`}
                onKeyDown={(e) => handleKeyDown(e, setActive, !active)}

                >
                <span>{title}</span>
                <small>{item.post_type.display_name}</small>
                <div className="svg-icon">
                    <TriangleIcon />
                </div>
            </div>
            <div className="page-details" tabIndex="-1">
                <label for={`detail-${item.id}`}>Etykieta nawigacji</label>
                <input className="input-menu" id={`detail-${item.id}`} tabIndex={active ? "0" : "-1"} type="text" value={title} onChange={(e) => setTitle(e.target.value)} onBlur={() => props.updateItem('title', title)} />
                {item && item.post_type && item.post_type.slug == "custom-link" &&
                <>
                    <label for={`link-${item.id}`}>Link</label>
                    <input className="input-menu" id={`link-${item.id}`} tabIndex={active ? "0" : "-1"} type="text" value={url} onChange={(e) => setUrl(e.target.value)} onBlur={() => props.updateItem('url', url)} />
                </>
                }
                <div className="target">
                    <input type="checkbox" id={`target-${item.id}`} tabIndex={active ? "0" : "-1"} className="target-checkbox" checked={target} onChange={() => { setTarget(!target); props.updateItem('target', !target ? true : "false") }} />
                    <label for={`target-${item.id}`}>Otwórz odnośnik w nowej zakładce</label>
                </div>
                <div className="actions">
                    <div className="remove action-btn" onKeyDown={(e) => handleKeyDown(e, props.removeItemMenu, item)} onClick={() => props.removeItemMenu(item)} tabIndex={active ? "0" : "-1"}>
                        Usuń
                    </div>
                    {props.activeParentAdd !== "children" && !(String(item.id)).includes("new") &&
                        <div className="add action-btn" onKeyDown={(e) => handleKeyDown(e, props.changeActiveParentAdd, (props.activeParentAdd !== props.itemIndex ? props.itemIndex : null))} tabIndex={active ? "0" : "-1"} onClick={() => props.changeActiveParentAdd(props.activeParentAdd !== props.itemIndex ? props.itemIndex : null)}>
                            {props.activeParentAdd == props.itemIndex ? 'Zakończ dodawanie menu podrzędnego' : 'Dodaj menu podrzędne'}
                        </div>
                    }
                </div>
            </div>
        </div>  
    );
}

export default MenuItem;
