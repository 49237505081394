import React, { useState, useEffect } from 'react';
import logout from './../../../services/logout';
import { useSelector, useDispatch } from 'react-redux'
import ApiUrl from './../../../services/ApiUrl';
import axios from 'axios';
import PlusWcag from './../../../svg-components/PlusWcag';
import MinusWcag from './../../../svg-components/MinusWcag';
import Button from './../../button/Button';
import Select from './../../select/Select';
import WebUrl from './../../../services/WebUrl';

const Header = (props) => {
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false);
    let lang = useSelector(state => state.lang);
    let languages = useSelector(state => state.languages);
    let [ languagesData, setLanguagesData ] = useState(false);
    let [ clicked, setClicked ] = useState(false);
    let [ contrastActive, setContrastActive ] = useState(false);
    let [ hamburger, setHamburger ] = useState(false);

    useEffect(() => {
        if (!load && languages && languages.length > 0) {
            ;(async function() {
                // await checkSessionSelectedLang();
                await createLanguagesData();
                await setLoad(true);
            })()
        }
    },[load, languages]);

    useEffect(() => {
        if ( !load ) {
            if(localStorage.getItem('contrast')) {
                let checkContrast = document.body.classList.contains('contrast-wcag');
                if(!checkContrast) {
                    document.body.classList.add('contrast-wcag');
                    setContrastActive(true)
                }
                dispatch({ type: 'CONTRAST_CHANGE', value: true })
            } else {
                dispatch({ type: 'CONTRAST_CHANGE', value: false })
            }
        }
    }, [load])

    const createLanguagesData = () => {
        let newData = [];
        languages.map((lang, index) => {
            newData = [...newData, {
                value: lang.id,
                text: lang.shortcode,
                ariaLabel: `Język ${(lang.name).toLowerCase()}`
            }]
        })
        setLanguagesData(newData);
    }

    const changeLanguage = (id) => {
        let value;
        languages.map(lang => {
            if(lang.id == id) {
                value = {
                    shortcode: lang.shortcode, id: lang.id
                }
            }
        });

        if(value) {
            dispatch({ type: 'LANG', value: value })
        }

        window.localStorage.setItem('LANG', JSON.stringify(value));
    }

    const checkSessionSelectedLang = () => {
        if(typeof window !== "undefined") {
            if(window.localStorage.getItem('LANG')) {
                dispatch({ type: 'LANG', value: JSON.parse(window.localStorage.getItem('LANG')) })
            }
        }
    }
    
    const clearCache = async() => {
        if(!clicked) {
            setClicked(true);
            await axios({
                method: 'get',
                url: `${ApiUrl()}/clearCache`,
                headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                setClicked(false);
                if(res.status == "success") {
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Cache na ${WebUrl()} zostały wyczyszczone</p>` })
                }else{
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować później.</p>` })
                }
            })
        }
    }

    let handleKeyDown = e => {
        if (e.key === 'Enter') {
            changeContrast()
        }
    }

    let changeContrast = () => {
        setContrastActive(!contrastActive)
        changeLayout();
    }

    const changeLayout = () => {
        let checkContrast = document.body.classList.contains('contrast-wcag');
        if(!checkContrast) {
            window.localStorage.setItem('contrast', true);
            document.body.classList.add('contrast-wcag');
        } else{
            window.localStorage.removeItem('contrast');
            document.body.classList.remove('contrast-wcag');
        }
        dispatch({ type: 'CONTRAST_CHANGE', value: !checkContrast })
    }

    const changeFontSize = data => {
        let el             = document.querySelector('html');
        let styles         = getComputedStyle(el)
        let actualFontSize = parseInt(styles.fontSize.replace('px', ''));
        let maxSize        = 26;
        let minSize        = 14;
        let newFontSize;

        if ( data === 'more') {
            newFontSize = (actualFontSize + 1) <= maxSize ? actualFontSize + 1 : maxSize
        } 
        if ( data === 'less') {
            newFontSize = (actualFontSize - 1) >= minSize ? actualFontSize - 1 : minSize
        }
        if ( data === 'reset') {
            newFontSize = 16
        }
        el.style.fontSize = newFontSize + 'px';
    }

    let handleKeyDownFontSize = (e, label) => {
        if (e.key === 'Enter') {
            changeFontSize(label)
        }
    }

    let handleKeyDownLogout = (e) => {
        if (e.key === 'Enter') {
            logout(props);
        }
    }

    let handleKeyDownHamburger = (e) => {
        if (e.key === 'Enter') {
            setHamburger(!hamburger)
        }
    }

    return (
        <header>
            <nav>
                <div className="mobile-hamburger">
                    <div className={"hamburger-wrap" + ( hamburger ? ' active': '' )} tabIndex="0" role="button" aria-label={hamburger ? "Zamknij menu opcji" : "Otwórz menu opcji"} onKeyDown={(e) => handleKeyDownHamburger(e)}>
                        <div class={"hamburger" + ( hamburger ? ' is-active': '' )} onClick={ () => setHamburger(!hamburger)}>
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </div>
                    </div>
                </div>
                <div className={hamburger ? "nav-items active" : "nav-items"}>
                    <div className="wcag">
                        <div className={ contrastActive ? "contrast active" : "contrast"} tabIndex="0" role="menu" aria-label="Wysoki kontrast" onKeyDown={ (e) => handleKeyDown(e) }>
                            <a className="icon" title="Wysoki kontrast" onClick={() => changeContrast() }>
                                <div className="circle" />
                                <div className="circle full" />
                            </a>
                            <p onClick={ () => changeContrast() }>Wysoki kontrast</p>
                        </div>
                        <div className="font-property">
                            <div className="font-icon" 
                                role="button" 
                                tabIndex="0" 
                                onKeyDown={ (e) => handleKeyDownFontSize(e, 'more') }
                                onClick={ () => changeFontSize('more') }>
                                <PlusWcag />
                            </div>
                            <p role="button" 
                                aria-label="Przywróć domyślny rozmiar czcionki"
                                tabIndex="0"
                                onKeyDown={ (e) => handleKeyDownFontSize(e, 'reset') }
                                onClick={ () => changeFontSize('reset') } >A</p>
                            <div className="font-icon"
                                role="button" 
                                tabIndex="0"
                                onKeyDown={ (e) => handleKeyDownFontSize(e, 'less') }
                                onClick={ () => changeFontSize('less') } >
                                <MinusWcag />
                            </div>
                        </div>
                    </div>
                    <div className="options">
                        <div className="cache">
                            <Button title={"Wyczyść cache"} setFunction={() => clearCache()} />
                        </div>
                        <div className="language">
                            <Select name="language"
                                ariaLabel="Wybierz język dla którego mamy pobrać zawartość panelu administracyjnego"
                                data={ languagesData } 
                                value={ lang.id }
                                handle={ (id) => { changeLanguage(id) } } />
                        </div>
                        <div className="hello">
                            Witaj, {props.user && props.user.login}
                        </div>
                        <div className="logout">
                            <a onKeyDown={(e) => handleKeyDownLogout(e)} onClick={ () => logout(props) }>
                                <i role="button" tabIndex="0" aria-label="Wyloguj się" className="material-icons">power_settings_new</i>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
