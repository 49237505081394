import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Editor from '../../../editor/Editor';
import Link from './../../../link/Link';
import Button from './../../../button/Button';
import MediaLoad from '../../../media-load/MediaLoad';
import Select from './../../../select/Select';
import Textarea from './../../../input/Textarea';

let validateNumber = 0;
const BoxImageReadMore = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                text: '',
                textWide: 'false',
                bgImage: '',
                // links: [
                //     {
                //         url: '',
                //         target: "false",
                //         text: '',
                //         uppercase: 'initial'
                //     },
                // ]
                link: {
                    url: '',
                    target: "false",
                    text: '',
                    uppercase: 'initial'
                },
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length <= 1) {
                props.onChange({
                    text: '',
                    textWide: 'false',
                    bgImage: '',
                    // links: [
                    //     {
                    //         url: '',
                    //         target: "false",
                    //         text: '',
                    //         uppercase: 'initial'
                    //     },
                    // ]
                    link: {
                        url: '',
                        target: "false",
                        text: '',
                        uppercase: 'initial'
                    },
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateFields = (val, index) => {
        let newData = props.data.links;
        newData = newData.map((item, i) => i == index ? item = val : item);
        saveData(newData);
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    const saveData = (data) => {
        props.onChange({
            ...props.data,
            links: data
        }, props.index)
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.box-image-read-more-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    const rolledSection = (index) => {
        if(typeof window !== "undefined") {
            let sections = document.querySelectorAll(`.box-image-read-more-${props.index} .box-image-read-more-content`);
            sections[index].children[1].classList.toggle('active');
        }
    }

    const addItem = () => {
        let newData = [...props.data.links, {
            url: '',
            target: "false",
            text: '',
            uppercase: 'initial'
        }]
        saveData(newData);
    }

    const removeItem = (index) => {
        let newData = props.data.links.filter((item, i) => i == index ? false : item);
        saveData(newData);
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(props.data.links);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        saveData(items);
    }

    const options = [
        {
            value: "false",
            text: "Nie ograniczaj szerokości"
        },
        {
            value: "true",
            text: "Ogranicz szerokość"
        },
    ]

    return (
        <div className={error ? `section-wrapper box-image-read-more error-section box-image-read-more-${props.index}` : `section-wrapper box-image-read-more box-image-read-more-${props.index}`}>
                <div className="row-section border">
                    {/* <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateValue(val, 'text') } value={props.data.text} label="Treść" /> */}
                    <Textarea  required={ true }
                        forceValidate={ forceValidate }
                        setFunction={ val => updateValue(val, 'text') }
                        value={props.data.text}
                        label={ 'Treść' }
                    />
                    <div className="select-options">
                        <Select name="status"
                            data={ options } 
                            value={ props.data.textWide }
                            label={"Szerokość"}
                            handle={ (val) => updateValue(val, 'textWide') } 
                        />
                    </div>
                </div>
                <div className="row-section">
                    <MediaLoad forceValidate={ forceValidate } setFunction={ val => updateValue(val, 'bgImage') } value={props.data.bgImage} label="Tło" />
                </div>
                <div className="row-section">
                    <Link value={props.data.link} setFunction={ val => updateValue(val, 'link')} label="Link" />
                </div>
                {/* <DragDropContext onDragEnd={handleRLDDChange}>
                <Droppable droppableId="section-items">
                    {(provided) => (
                        <div className="section-items" {...provided.droppableProps} ref={provided.innerRef}>
                        {props.data && props.data.links && props.data.links.map((item, index) => (
                            <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                {(provided) => provided && (
                                    <div className="box-image-read-more-content" {...provided.draggableProps} ref={provided.innerRef}>
                                        <div className="section-index">
                                            <span onClick={() => rolledSection(index)}
                                                aria-label="Zwiń lub rozwiń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, rolledSection, index)}>Przycisk {index + 1}</span>
                                            {!error &&
                                            <i {...provided.dragHandleProps} 
                                                className="material-icons"
                                                aria-label="Przeciągnij sekcje"
                                                tabIndex="0"
                                                role="button">open_with</i>
                                            }
                                            <i onClick={() => removeItem(index)} 
                                                aria-label="Usuń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, removeItem, index)}
                                                className="material-icons remove-item-icon">delete</i>
                                        </div>
                                        <div className="content">
                                            <div className="row-section">
                                                <div className="row-section">
                                                    <Link value={item} setFunction={ val => updateFields(val, index)} forceValidate={ forceValidate } />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext> */}
            {/* <div className="add-item-in-section">
                <Button title="Dodaj link" setFunction={() => addItem()} />   
            </div> */}
        </div>
    );
}

export default BoxImageReadMore;